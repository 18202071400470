import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import TourOperatorReviewOverview from "@src/components/content-tour-operator-reviews-overview"
import TourOperatorReview from "@src/components/content-tour-operator-review"
import AddOperatorReview from "@src/components/content-operator-review-add"
import InputDropdown from "@src/components/input-dropdown"
import { REVIEW_SORT_OPTIONS } from "@src/utils/constants/review-sort-options"
const StyledOperatorReviewOverview = styled(TourOperatorReviewOverview)`
  padding: 5px;
`
const StyledTourOperatorReview = styled(TourOperatorReview)`
  margin-bottom: 24px;
`
const ReviewContainer = styled.div``
const OperatorReviewsContainer = styled.div`
  & > ${ReviewContainer} {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-family: "system-ui";
    & > span {
      font-weight: 600;
    }
    & .sort-by-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
    }
    & .sorting {
      max-width: 448px;
      margin: 6px 0 20px;
      & button {
        height: 50px;
        padding: 10px 14px 10px 14px;
        border-radius: 8px;
        color: inherit;
        &:after {
          font-family: "icons-10a-v2" !important;
          content: "\\e90d";
          border: none;
          right: 30px;
          bottom: 30px;
        }
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > ${ReviewContainer} {
      margin-top: 10px;
    }
  }
`
const TourOperatorReviews = ({
  reviews,
  loadReviews,
  className,
  operatorId,
  onReviewAdded,
}) => {
  const [sorting, setSorting] = useState("Date_DESC")
  const [content, setContent] = useState(reviews.content)
  const handleSortingChange = value => setSorting(value)
  useEffect(() => {
    if (sorting) {
      const reviewsCopy = [...reviews.content]
      reviewsCopy.sort((a, b) => {
        if (sorting === "Rating_ASC") return a.rating - b.rating
        if (sorting === "Rating_DESC") return b.rating - a.rating
        if (sorting === "Date_ASC")
          return Date.parse(a.date) - Date.parse(b.date)
        if (sorting === "Date_DESC")
          return Date.parse(b.date) - Date.parse(a.date)
      })
      setContent(reviewsCopy)
    }
  }, [sorting, reviews])
  return (
    <OperatorReviewsContainer className={className}>
      <StyledOperatorReviewOverview
        overall={reviews.overall}
        numReviews={reviews.numReviews}
      />
      <ReviewContainer>
        {content.length ? (
          <>
            <span className="sort-by-text">Sort By</span>
            <InputDropdown
              id="sort-by"
              name="sort-by"
              className="sorting"
              options={REVIEW_SORT_OPTIONS}
              value={
                (sorting &&
                  REVIEW_SORT_OPTIONS.find(item => item.value === sorting)) ||
                null
              }
              onChange={value =>
                handleSortingChange(value ? value.value : null)
              }
            />
            {content.map((singleReview, index) => (
              <StyledTourOperatorReview key={index} review={singleReview} />
            ))}
          </>
        ) : (
          <p>There are no written reviews yet.</p>
        )}
        {loadReviews ? (
          loadReviews
        ) : (
          <span>{`${reviews.numReviews.total} total ${
            reviews.numReviews.total == 1 ? `rating` : `ratings`
          }, ${reviews.content.length} with reviews`}</span>
        )}
        <AddOperatorReview
          operatorId={operatorId}
          onReviewAdded={onReviewAdded}
        />
      </ReviewContainer>
    </OperatorReviewsContainer>
  )
}
TourOperatorReviews.propTypes = {
  reviews: PropTypes.shape({
    overall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    numReviews: PropTypes.shape({
      total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      five: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      four: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      three: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      two: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      one: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    content: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        date: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
  loadReviews: PropTypes.node,
  className: PropTypes.string,
  operatorId: PropTypes.number,
  onReviewAdded: PropTypes.func,
}
export default TourOperatorReviews
