import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import ImageGallery from "@src/components/core-image-gallery"
import Button from "@src/components/core-button"

const TourImage = styled.div`
  position: relative;
  width: 100%;
  height: 215px;
  & > button {
    display: none;
  }
  & > .header-seen-in {
    display: none;
  }
  & > .banner {
    z-index: 2;
    font-size: 148x;
    font-weight: 700;
    line-height: 24px;
    position: absolute;
    width: 300px;
    text-align: center;
    top: 44px;
    left: -84px;
    padding: 10px 0;
    color: white;
    font-weight: bold;
    transform: rotate(-45deg);
    text-transform: uppercase;
    background-color: #3a6968; // Natasha
    &.sold-out {
      background-color: #cf2f43; // Natasha
    }
    clip-path: polygon(78px 0px, 222px 0px, 266px 100%, 35px 100%);
  }
  @media only screen and (min-width: 393px) {
    display: grid;
    grid-template-columns: 125px 1fr;
    column-gap: 16px;
    & > .banner {
      left: 57px;
    }
  }
  @media only screen and (${props =>
      props.theme.screen.tablet.min}) and (${props =>
      props.theme.screen.tablet.max}) {
    height: 525px;
    & > .banner {
      left: -84px;
    }
  }

  @media only screen and (${props => props.theme.screen.tablet.max}) {
    & > button {
      display: block;
      position: absolute;
      z-index: 1;
      width: 65px;
      padding: 7px 0;
      font-family: system-ui;
      font-size: 12px;
      border-radius: 8px;
      right: 12px;
      bottom: 12px;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-template-columns: 150px 1fr;
    height: 525px;
    & > .banner {
      left: 82px;
    }
    & > .header-seen-in {
      position: absolute;
      bottom: 24px;
      right: 24px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 8px;
      gap: 20px;
      & > .lazyload-wrapper {
        width: 100px;
        & img {
          filter: brightness(0);
        }
      }
    }
  }
  @media only screen and (${props =>
      props.theme.screen.medium.min}) and (${props =>
      props.theme.screen.medium.max}) {
    & > .banner {
      left: -84px;
    }
    & > button {
      display: block;
      position: absolute;
      z-index: 1;
      width: 65px;
      padding: 7px 0;
      font-family: system-ui;
      font-size: 12px;
      border-radius: 8px;
      left: 12px;
      bottom: 12px;
    }
  }
`
const HeaderImageWrapper = styled(ImageWrapper)`
  grid-column: 2 / 3;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  @media only screen and (${props =>
      props.theme.screen.tablet.min}) and (${props =>
      props.theme.screen.tablet.max}) {
    grid-column: 1 / 3;
  }
  @media only screen and (${props =>
      props.theme.screen.medium.min}) and (${props =>
      props.theme.screen.medium.max}) {
    grid-column: 1 / 3;
  }
`
const SmallerImages = styled.div`
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (${props =>
      props.theme.screen.tablet.min}) and (${props =>
      props.theme.screen.tablet.max}) {
    display: none;
  }
  @media only screen and (${props =>
      props.theme.screen.medium.min}) and (${props =>
      props.theme.screen.medium.max}) {
    display: none;
  }
`
const SmallImageWrapper = styled(ImageWrapper)`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  cursor: pointer;
  display: none;
  position: relative;

  @media only screen and (min-width: 393px) {
    &:nth-child(-n + 2) {
      display: block;
    }
    &:nth-child(2):has(img) {
      img {
        filter: brightness(40%);
      }
      :before {
        content: "See More";
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 1;
      }
    }
    & :not(:has(img)) {
      :before {
        content: "No Image";
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: block;
    &:nth-child(2):has(img) {
      img {
        filter: none;
      }
      :before {
        content: "";
      }
    }
    &:last-child:has(img) {
      img {
        filter: brightness(40%);
      }
      :before {
        content: "See More";
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 1;
      }
    }
  }
`

const SingleTourHeader = ({ img, gallery, mediaPartners, spotsRemaining }) => {
  const [showGallery, setShowGallery] = useState(false)
  return (
    <TourImage
      className="single-tour-image"
      onClick={() => setShowGallery(true)}
    >
      {spotsRemaining && (
        <div
          className={`banner ${
            spotsRemaining === "Sold Out" ? "sold-out" : "few-spots"
          }`}
        >
          {spotsRemaining}
        </div>
      )}
      <Button onClick={() => setShowGallery(true)} color="outline-black">
        View all
      </Button>
      {mediaPartners && mediaPartners.length && (
        <div className="header-seen-in">
          {mediaPartners.map(partner => (
            <Image key={partner.title} {...partner.logoTile} />
          ))}
        </div>
      )}
      <SmallerImages>
        {Array.from(Array(5).keys()).map(number => {
          let thumbnailSrc
          let srcSet = ""
          if (gallery && gallery[number] && gallery[number]?.sizes.length) {
            const thumbnailImages = gallery[number].sizes.filter(image =>
              image.name.includes("Core 4:3 Aspect Ratio")
            )
            thumbnailImages.forEach((image, index) => {
              if (index === 0) {
                thumbnailSrc = image.src
              }
              srcSet += `${image.src} ${index + 1}x${
                index !== thumbnailImages.length - 1 ? `,` : ""
              }`
            })
          }
          return (
            <Image
              key={number}
              wrapper={SmallImageWrapper}
              placeholder
              alt={gallery && gallery[number] ? gallery[number].alt : null}
              src={
                thumbnailSrc
                  ? thumbnailSrc
                  : gallery && gallery[number] && gallery[number].src
              }
              srcSet={srcSet}
            />
          )
        })}
      </SmallerImages>
      <Image lazyload={false} wrapper={HeaderImageWrapper} {...img} />
      {gallery && gallery.length >= 1 && (
        <ImageGallery
          variant="popup"
          images={gallery}
          open={showGallery}
          onClose={() => setShowGallery(false)}
        />
      )}
    </TourImage>
  )
}

SingleTourHeader.propTypes = {
  img: PropTypes.shape({ ...ImagePropTypes }),
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      sizes: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, src: PropTypes.string })
      ),
    })
  ),
  mediaPartners: PropTypes.object,
  spotsRemaining: PropTypes.string,
}

export default SingleTourHeader
