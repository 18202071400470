import React from "react"
import styled from "styled-components"
import Image from "@src/components/core-image"

const QuoteDiv = styled.div`
  margin: 30px 0;
  background: #8bbbaf; // Natasha design
  padding: 40px 20px 20px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .lazyload-wrapper {
      width: 320px;
      height: 240px;
      & img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }
    & .quote {
      margin: 20px;
      // Yellow Border
      & h3 {
        font-family: "system-ui";
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #2b4346; //Natasha design
      }
      & p {
        font-family: "system-ui";
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: ${props => props.theme.white};
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > div {
      height: auto;
      & .quote {
        & h3 {
          font-size: 32px;
          line-height: 40px;
        }
        & p {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding: 40px 20px;
    & > div {
      display: grid;
      grid-template-columns: 320px 1fr;
      gap: 20px;
      max-width: ${props => props.theme.screen.medium.contentMaxWidths}px;
      margin: auto;
    }
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    padding: 40px 80px;
    & > div {
      max-width: ${props => props.theme.screen.desktop.contentMaxWidths}px;
    }
  }
`
const cloudfrontLink =
  "https://d3owbckoeihl9o.cloudfront.net/images/static-images/quote-social-proof/"
const TenAQuote = () => {
  return (
    <QuoteDiv>
      <div>
        <Image
          placeholder
          srcSet={`${cloudfrontLink}PatagoniaCouple@3.png 3x, ${cloudfrontLink}PatagoniaCouple@2.png 2x, ${cloudfrontLink}PatagoniaCouple@1.png 1x`}
          src={`${cloudfrontLink}PatagoniaCouple@1.png`}
          alt="10adventures-quote"
          width={320}
          height={240}
        />
        <div className="quote">
          <h3>
            We make it easy for travellers to book private travel experiences,
            so they can make memories exploring the world with the people that
            matter most.
          </h3>
          <p>&#8212; Richard Campbell, CEO</p>
        </div>
      </div>
    </QuoteDiv>
  )
}

export default TenAQuote
