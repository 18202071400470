import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { DIFFICULTY_RATINGS } from "@src/utils/constants/difficulty-ratings"
const RatingContainer = styled.span`
  ${props =>
    props.variant === "green"
      ? css`
          display: flex;
          align-items: flex-end;
          color: #41c689; // Natasha design
          margin-bottom: 20px;
          & > p {
            margin: 0 0 0 12px;
          }
        `
      : "height: 15px;"}
`

const RatingBar = styled.span`
  display: inline-block;
  ${props =>
    props.variant === "green"
      ? css`
          display: inline-block;
          background-color: ${props =>
            props.active ? "#41C689" : "#D9D9D9"}; // Natasha design
          margin-right: 4px;
          width: 4px;
          border-radius: 1px;
          &:nth-child(1) {
            height: 8px;
          }
          &:nth-child(2) {
            height: 12px;
          }
          &:nth-child(3) {
            height: 16px;
          }
          &:nth-child(4) {
            height: 20px;
          }
          &:nth-child(5) {
            height: 24px;
          }
        `
      : css`
          width: 12px;
          background-color: ${props => props.theme.primary};
          opacity: ${props => (props.active ? "1" : "0.3")};
          height: 15px;
          margin-left: 3px;
          transform: skewX(-20deg);
        `}
`

const RatingBars = ({ rating, ariaLabel, className, children, variant }) => {
  return (
    <RatingContainer
      aria-label={ariaLabel}
      className={className}
      variant={variant}
    >
      {children}
      {
        <>
          {Array.from(Array(5), (value, index) => (
            <RatingBar
              className="rating-bar"
              key={index}
              variant={variant}
              active={index < rating}
            />
          ))}
          {variant === "green" && (
            <p>
              {DIFFICULTY_RATINGS.find(item => item.value === rating).title}
            </p>
          )}
        </>
      }
    </RatingContainer>
  )
}

RatingBars.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["yellow", "green"]),
}

export default RatingBars
