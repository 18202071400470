import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Currency from "@src/components/core-value-local-currency"
import Image from "@src/components/core-image"
import Button from "@src/components/core-button"

const CTA = styled.div`
  display: none;
  @media only screen and (${props => props.theme.screen.medium.min}) {
    display: block;
    padding: 24px;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    background: ${props => props.theme.backgroundPaleYellow};
    font-family: "system-ui";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;
    & .cta-price {
      color: ${props => props.theme.green2};
      font-family: system-ui;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      margin-left: 4px;
      & .currency-code {
        margin-left: 4px;
        font-size: 24px;
      }
    }
    & .made-for-you {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0px;
      margin: 15px 0 10px;
    }
    & .details {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      & p {
        margin: 0 0 0 10px;
        width: 90%;
      }
    }
    & button {
      border-radius: 8px;
      padding: 10px 18px;
      height: 44px;
    }
  }
`

const BookingCTA = ({ price, bookingURL, askQuestion, groupTrip }) => {
  return (
    <div className="booking-cta-below">
      <CTA>
        <span>
          From{" "}
          {price && (
            <Currency
              className="cta-price"
              amount={price.amount}
              code={price?.currency?.code}
              showAbbr="after"
              applyRounding={!price.discount ? "auto" : false}
              discounted={!!price.discount}
            />
          )}
        </span>
        <p className="made-for-you">This tour was made for you!</p>
        <div className="details">
          <Image src="/icons/travel-cta.svg" />
          <p>{`Start filling out your booking details. Once you submit your request, we'll review availability for this tour and contact you within 24 hours to further customize your perfect adventure.`}</p>
        </div>
        {bookingURL && (
          <Button
            name="tour-start-booking-below-fold"
            color="yellow-black"
            size="full-width-large"
            href={bookingURL}
            rel="nofollow"
            external
            generateDataLayer={() => ({
              "highlight-tile": "Prices-below-fold",
            })}
          >
            Start Your Booking
          </Button>
        )}
        {askQuestion}
      </CTA>
      {groupTrip}
    </div>
  )
}

export default BookingCTA
BookingCTA.propTypes = {
  price: PropTypes.object,
  bookingURL: PropTypes.string,
  askQuestion: PropTypes.node,
  groupTrip: PropTypes.node,
}
