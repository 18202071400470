import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import { scroller } from "react-scroll"
import PrimaryLayout from "@src/layouts/primary"
import ResponsiveSection from "@src/components/container-responsive-section"
import TourTitle from "@src/components/content-tour-title"
import SingleTourHeader from "@src/components/page-header-single-tour"
import HtmlContent from "@src/components/core-value-html"
import Icon from "@src/components/core-icon"
import TourHighlightList from "@src/components/content-single-tour-highlight-list"
import ReadMoreText from "@src/components/core-value-read-more-text"
import DayOfWeekPricesTable from "@src/components/content-single-tour-day-of-week-prices"
import FixedDatePricesTable from "@src/components/content-single-tour-fixed-date-prices"
import TourList from "@src/components/content-tour-list"
import ItineraryDayTile from "@src/components/content-single-tour-itinerary-day-tile"
import HotelTile, {
  HotelTilePropTypes,
} from "@src/components/content-hotel-tile"
import FAQTile from "@src/components/content-single-tour-faq"
import Button from "@src/components/core-button"
import { useTourBreadcrumbs } from "@src/hooks/useTourBreadcrumbs"
import { ImagePropTypes } from "@src/components/core-image"
import { PricePropTypes } from "@src/graphql-fragments/prices"
import { TourDetailTilePropTypes } from "@src/components/content-tour-detail-tile"
import ImageGallery from "@src/components/core-image-gallery"
import SocialProofReasons from "@src/components/content-social-proof-reasons"
import { DUMMY_MAP_TITLE } from "@src/utils/constants/tour-map"
import TourOperatorReviews from "@src/components/content-tour-operator-reviews"
import TourOperatorReviewsService from "@src/services/reviews/tour-operator-reviews"
import { formatDate } from "@src/utils/dates"
import { useTourContinents } from "@src/hooks/useTourContinents"
import { getRegionChildTypeName } from "@src/utils/getRegionChildTypeName"
import BreadcrumbsV2 from "@src/components/nav-content-breadcrumbs-v2"
import generateBreadcrumbList from "@src/utils/structured-data/generateBreadcrumbList"
import generateProduct from "@src/utils/structured-data/generateProduct"
import { SITE_DOMAIN } from "@src/utils/constants"
import CollapsibleContent from "@src/components/core-collapsible-content"
import RatingBars from "@src/components/core-rating-bars"
import TenAQuote from "@src/components/social-proof-10A-quote"
import BookingProcess from "@src/components/content-tour-booking-process"
import BookingCTA from "@src/components/content-tour-booking-cta"
import AboutTour from "@src/components/content-tour-about"
import { convertToMonths } from "@src/utils/convert-number-to-month"
import { TOUR_DIFFICULTY_OPTIONS } from "@src/utils/constants/tour-list-options"
import BookingPriceCTA from "@src/components/content-tour-booking-price-cta"
import Image from "@src/components/core-image"
import StickyBookingPriceCTA from "@src/components/content-tour-sticky-booking-price-cta"
import HappinessGuarantee from "@src/components/content-tour-happiness-guarantee"
import { LayerContext } from "@src/context/layer-context"
import { instantBookings } from "@src/utils/constants/instant-booking-tours"
import { LocaleContext } from "@src/context/locale-context"
import ModalContainer from "@src/components/container-modal"
import QuestionForm from "@src/components/form-question"
import SocialProofMediaPartners from "@src/components/content-social-proof-media-partners"
import VideoTestimonials from "@src/components/content-social-proof-video-testimonials"
import { TOUR_CATEGORY_ICONS } from "@src/utils/constants/tour-category-icons"
import { TOUR_REGION_CATEGORY_INCLUSION_IDS } from "@src/utils/constants/region-activity-inclusions"
import { screen } from "@src/styles/theme"
import { useMediaPartners } from "@src/hooks/useMediaPartners"
import WhyTravellers from "@src/components/content-tour-why-travellers"
import {
  GroupTrips,
  GroupTripTile,
  GroupTripForm,
} from "@src/components/content-tour-group-trips"
import { checkTourTnC } from "@src/utils/tour-tnc"
import LifetimeDeposit from "@src/components/content-tour-lifetime-deposit"

// Clickable Map
const ClickableMap = styled.div`
  position: relative;
  margin: 20px 0 auto;
  width: 100%;
  max-width: 610px;
  height: 225px;
  & span {
    display: none;
  }
  :hover {
    cursor: pointer;
    & span {
      display: inline;
      position: absolute;
      color: ${props => props.theme.white};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: system-ui;
      font-size: 21px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
    }
    & img {
      filter: brightness(40%);
    }
  }
  & .lazyload-wrapper,
  & img {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & img {
    border-radius: 8px;
    object-fit: cover;
    object-position: top;
  }
`
// Ask A Question
const QuestionModal = styled(ModalContainer)`
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`
const QuestionButton = styled(Button)`
  border-radius: 8px;
  max-width: 610px;
  font-weight: bold;
  :hover {
    color: ${props => props.theme.white};
    background: ${props => props.theme.black};
  }

  margin: ${props => (props.$smallMax ? "0 0 10px" : "10px 0 0")};
  ${props =>
    props.$smallMax
      ? `@media only screen and (${props.theme.screen.medium.min}) {display: none;}`
      : ""}
`
// Content Tab & Image Container
const AccordionContent = styled.div`
  width: auto;
  & > .collapsible-div {
    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.borderGray};
    }
    & > .collapsible-header {
      align-items: center;
      :hover {
        color: ${props => props.theme.primary};
      }
      & > h3 {
        font-weight: 500;
        font-size: 20px;
        margin: 0;
      }
      & i {
        font-size: 20px;
        line-height: 1rem;
        &:not(.collapsible-icon) {
          margin-right: 10px;
        }
      }
      padding: 24px 0;
    }
    & > .collapsible-content {
      padding-bottom: 24px;
      & p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
    &.difficulty {
      & a {
        color: inherit;
        text-decoration: underline;
        font-weight: 500;
      }
    }
    &.included {
      & h4 {
        text-transform: uppercase;
        line-height: 26px;
        letter-spacing: 0.25px;
        margin-bottom: 4px;
        &.green {
          color: #108b59; // Natasha Design
        }
        &.red {
          color: #ff6133; // Natasha Design
        }
        &.yellow {
          color: ${props => props.theme.primary};
        }
      }
      & ul {
        line-height: 24px;
        & > li {
          padding-left: 25px;
          &:before {
            // Remove yellow dots
            border-radius: 0;
            background-color: transparent;
            // Required for custom bullet points with icon set
            font-family: "icons-10a-v2" !important;
            width: auto;
            height: auto;
          }
        }
      }
      .green + ul {
        & > li {
          &:before {
            content: "\\eb52";
            color: #108b59; // Natasha Design
          }
          &:has(ul) {
            & li:before {
              content: "\\eb52";
              color: #108b59; // Natasha Design
            }
          }
        }
      }
      .red + ul {
        & > li:before {
          content: "\\e987";
          color: #ff6133; // Natasha Design
        }
      }
      .yellow + ul {
        & > li:before {
          content: "\\ea28";
          color: ${props => props.theme.primary};
        }
      }
    }
    &.faq {
      & ul {
        line-height: 24px;
        & > li {
          padding-left: 25px;
          &:before {
            // Remove yellow dots
            border-radius: 0;
            background-color: transparent;
            // Required for custom bullet points with icon set
            font-family: "icons-10a-v2" !important;
            width: auto;
            height: auto;
            content: "\\ea28";
            color: ${props => props.theme.primary};
          }
          // Required to center with top line
          &:has(> ul):before {
            position: absolute;
            top: 12px;
          }
          // Temporary until primary theme file can be refactored so all bullets can have uniform styling
          & > ul {
            &:before {
              content: none;
            }
          }
        }
      }
    }
    // For discounts via HTML
    &.dates {
      & li > strong {
        color: red;
      }
    }
    & .load-review-button-container {
      & button {
        height: 56px;
        padding: 16px 28px 16px 28px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.005em;
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .collapsible-div {
      & .load-review-button-container {
        max-width: 448px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    & .dates {
      & .cta-buttons {
        max-width: 380px;
        padding: 0 60px 24px 0;
      }
    }
  }
`

// Main Layout
const AboveFold = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 24px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 16px;
  & > .seen-in {
    margin: 16px 0;
    & h2 {
      font-family: system-ui;
      font-size: 26px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #1f1f23; // Natasha Design
    }
    & > div {
      background: #1f1f23; // Natasha Design
      padding: 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      & > .lazyload-wrapper {
        width: 90px;
        & img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .seen-in {
      display: none;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    max-width: ${props => props.theme.screen.medium.contentMaxWidths}px;
    grid-template-columns: auto 335px;
    padding: 16px 0;

    & > .breadcrumbs {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
    & > .tour-title-container {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }
    & > .about-tour-container {
      grid-row: 4 / 5;
      grid-column: 1 / 3;
    }
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    max-width: ${props => props.theme.screen.large.contentMaxWidths}px;
  }

  @media only screen and (${props => props.theme.screen.desktop.min}) {
    max-width: ${props => props.theme.screen.desktop.contentMaxWidths}px;
  }
`
const BelowFold = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 24px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 16px;
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 16px;
  }
  & .green-header {
    font-family: "system-ui";
    color: ${props => props.theme.green2};
    font-family: system-ui;
    letter-spacing: 0.5px;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    max-width: ${props => props.theme.screen.medium.contentMaxWidths}px;
    grid-template-columns: auto 335px;
    padding: 16px 0;
    & > ${AccordionContent} {
      position: relative;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    & > .booking-cta-below {
      height: fit-content;
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      margin-top: 10px;
      position: sticky;
      top: ${props => props.theme.header.medium.height}px;
    }
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    max-width: ${props => props.theme.screen.large.contentMaxWidths}px;
    & > .booking-cta-below {
      top: ${props => props.theme.header.large.height}px;
    }
  }

  @media only screen and (${props => props.theme.screen.desktop.min}) {
    max-width: ${props => props.theme.screen.desktop.contentMaxWidths}px;
  }
`

const RelatedToursSection = styled(ResponsiveSection)`
  margin-bottom: 40px;
`
const PageTemplate = ({
  data: { tenTours: data },
  pageContext: { pageUri, skipForBottomSeo, replaceURLs },
}) => {
  const {
    tour: {
      images,
      title,
      slug: tourSlug,
      spotsRemaining,
      tourHighlights,
      seoText,
      seo: pageSeo,
      tourDifficulty,
      price,
      priceFooter,
      priceHeader,
      dayofweekPrices,
      fixedDates,
      groupTypes,
      tourDuration,
      tourIcons,
      tourForMe,
      tourMonths,
      itinerary,
      accommodationText,
      accommodationGroups,
      tourIncluded,
      faqs,
      tourTnC,
      tourOperator,
      tourCategory,
      tourRegion,
      relatedTours,
      map,
    },
  } = data

  const [showHP, setShowHP] = useState(false)
  const [showGT, setShowGT] = useState(false)
  const [showLD, setShowLD] = useState(false)
  const [mapIsOpen, setMapIsOpen] = useState(false)
  const [isQuestionOpen, setIsQuestionOpen] = useState(false)
  const [isGroupTripOpen, setIsGroupTripOpen] = useState(false)
  const [bookingURL, setBookingURL] = useState(`/bookings/tour/${tourSlug}/`)
  const { headerBreadcrumbs } = useTourBreadcrumbs(tourRegion.id)
  const { currency } = useContext(LocaleContext)
  const tourContinents = useTourContinents()
  const mediaPartners = useMediaPartners().filter(partner =>
    ["National Geographic", "Fodor’s", "The Guardian"].includes(partner.title)
  )
  const terms = checkTourTnC(tourOperator.operatorTnC, tourTnC)
  // Click Refs
  const datesRef = useRef()
  const reviewsRef = useRef()
  // Review States & Ref
  const [reviews, setReviews] = useState({
    overall: tourOperator && tourOperator.reviews.overall,
    numReviews: tourOperator && { ...tourOperator.reviews.counts },
    content: [],
  })
  const [hasMoreReviews, setHasMoreReviews] = useState(false)
  const reviewsLoaded = useRef({ items: [], lastPage: 0, loadCount: 0 })
  // Open and close layers based on showHP state
  const { open: openLayer, close: closeLayer } = useContext(LayerContext)

  // Ratings
  const processRatingsData = useCallback(
    (overall, counts) =>
      setReviews(prev => ({ ...prev, overall: overall, numReviews: counts })),
    [setReviews]
  )
  const loadRatings = useCallback(() => {
    TourOperatorReviewsService.getOperatorRatings(tourOperator.id)
      .then(data =>
        processRatingsData(data.items.length && data.items[0][1], data.counts)
      )
      .catch(error => console.log(error))
  }, [tourOperator, processRatingsData])
  useEffect(() => loadRatings(), [loadRatings])
  // Reviews
  const processReviewsData = useCallback(
    (reviewsData, reset) => {
      if (reset) {
        reviewsLoaded.current.items = reviewsData.items || []
        reviewsLoaded.current.loadCount = 0
      } else if (reviewsData.items && reviewsData.items.length) {
        reviewsLoaded.current.items = reviewsLoaded.current.items.concat(
          reviewsData.items
        )
      }
      reviewsLoaded.current.loadCount++
      reviewsLoaded.current.lastPage = reviewsData.page
      // Required to only render first 4 reviews on page load
      const content =
        reviewsLoaded.current.loadCount === 1
          ? reviewsLoaded.current.items.slice(0, 4)
          : reviewsLoaded.current.items

      // State change to render reviews & read more reviews button
      setReviews(prev => ({ ...prev, content }))
      setHasMoreReviews(
        content.length < reviewsLoaded.current.items.length ||
          reviewsData.page < Math.ceil(reviewsData.total / reviewsData.perPage)
      )
    },
    [setReviews, setHasMoreReviews, reviewsLoaded]
  )
  const loadMoreReviews = useCallback(
    reset => {
      TourOperatorReviewsService.getOperatorReviews(tourOperator.id, {
        page: reset ? 1 : reviewsLoaded.current.lastPage + 1,
      }).then(reviewsData => {
        if (reviewsData) {
          const { items, ...paginationData } = reviewsData
          const reviewData = items
            .filter(item => item.text && item.text.trim().length !== 0)
            .map(filteredItem => ({
              name: filteredItem.user?.name,
              rating:
                filteredItem.ratings.length &&
                filteredItem.ratings[0].length &&
                filteredItem.ratings[0][1],
              date:
                filteredItem.time &&
                formatDate(new Date(filteredItem.time), "MMMM, yyyy"),
              content: filteredItem.text,
            }))
          processReviewsData({ items: reviewData, ...paginationData }, reset)
        }
      })
    },
    [tourOperator, processReviewsData, reviewsLoaded]
  )
  const handleLoadMoreReviews = useCallback(() => {
    loadMoreReviews()
  }, [loadMoreReviews])
  useEffect(() => {
    if (tourOperator && tourOperator.id) {
      loadMoreReviews(true)
    }
  }, [tourOperator, loadMoreReviews])

  const handleReviewAdded = useCallback(() => {
    loadMoreReviews(true)
    loadRatings()
    scroller.scrollTo("tour-operator-reviews", { offset: -125 })
  }, [loadMoreReviews, loadRatings])

  const AskQuestionModal = (
    <QuestionModal
      id="ask-question-modal"
      isOpen={isQuestionOpen}
      onClose={() => setIsQuestionOpen(false)}
      title={title}
    >
      <p>
        Chat with us on our{" "}
        <a
          href="/contact/"
          target="_blank"
          aria-label="Contact page, opens a new tab"
        >
          contact page
        </a>{" "}
        or fill out the form below!
      </p>
      <QuestionForm
        name="tour-question-zoho"
        pageName={title}
        pagePath={pageUri}
        operator={tourOperator.name}
        productPrice={price}
        onClose={() => setIsQuestionOpen(false)}
      />
    </QuestionModal>
  )
  const GroupTripModal = (
    <QuestionModal
      id="group-trip-modal"
      isOpen={isGroupTripOpen}
      onClose={() => setIsGroupTripOpen(false)}
      title={title}
    >
      <GroupTripForm
        pageName={title}
        pagePath={pageUri}
        operator={tourOperator.name}
        onClose={() => setIsGroupTripOpen(false)}
      />
    </QuestionModal>
  )

  const tabData = useMemo(() => {
    const extrasSplitText =
      tourIncluded.search("Optional Extras") >= 0
        ? "Optional Extras"
        : "Optional extras"
    const includedv2 = tourIncluded
      .replace(/<h2>(.*?)<\/h2>/, "")
      .replaceAll("h3", `h4`)
      .replaceAll("<h4>", `<h4 class="green">`)
      .replace(
        `<h4 class="green">Not included</h4>`,
        `<h4 class="red">Not included</h4>`
      )
      .replace(
        `<h4 class="green">${extrasSplitText}</h4>`,
        `<h4 class="yellow">${extrasSplitText}</h4>`
      )

    const result = [
      {
        title: "Overview",
        className: "overview",
        content: seoText ? (
          <>
            <h3 className="green-header">{`${title} Overview`}</h3>
            <HtmlContent
              html={seoText}
              prices={{ showAbbr: "after-full-size" }}
            />
          </>
        ) : undefined,
        icon: "v2-glasses",
      },
      {
        title: "Itinerary",
        className: "itinerary",
        content: itinerary ? (
          itinerary.days && itinerary.days.length ? (
            <>
              <h3 className="green-header">{`${title} Itinerary`}</h3>
              {itinerary.days.map((itinerary, index) => (
                <ItineraryDayTile
                  key={index}
                  variant="grey"
                  itinerary={itinerary}
                />
              ))}
            </>
          ) : undefined
        ) : undefined,
        icon: "v2-thick-Plane-Ticket-2",
      },
      {
        title: "What's Included",
        className: "included",
        content: (
          <HtmlContent
            html={includedv2}
            prices={{ showAbbr: "after-full-size" }}
          />
        ),
        icon: "v2-thick-Suitcase-2",
      },
      {
        title: "Dates & Prices",
        className: "dates",
        content: (
          <>
            {priceHeader && (
              <HtmlContent
                // Remove header
                html={priceHeader
                  .replace(/<h2>(.*?)<\/h2>/, "")
                  .replaceAll("<h3>", `<h3 class="fancy">`)}
                prices={{ showAbbr: "after-full-size" }}
              />
            )}
            {dayofweekPrices && (
              <DayOfWeekPricesTable
                dayofWeekData={dayofweekPrices}
                price={price}
                bookingURL={bookingURL}
              />
            )}
            {fixedDates && (
              <FixedDatePricesTable
                fixedDateData={fixedDates}
                bookingURL={bookingURL}
              />
            )}
            {priceFooter && (
              <HtmlContent
                html={priceFooter}
                prices={{ showAbbr: "after-full-size" }}
              />
            )}
            {!dayofweekPrices && !fixedDates && (
              <div className="cta-buttons">
                <Button
                  name="tour-book-now-general"
                  color="purple"
                  size="full-width-large"
                  icon="calendar-dates"
                  href={bookingURL}
                  rel="nofollow"
                  external
                  generateDataLayer={() => ({
                    "highlight-tile": "Dates & Prices",
                  })}
                >
                  Start Your Booking
                </Button>
              </div>
            )}
          </>
        ),
        icon: "v2-thick-Money",
      },
      {
        title: "Accommodation",
        className: "accommodation",
        content: (
          <>
            <h3 className="green-header">{`Accommodation for ${title}`}</h3>
            <HtmlContent
              html={accommodationText.replace(/<h2>(.*?)<\/h2>/, "")}
              prices={{ showAbbr: "after-full-size" }}
            />
            {accommodationGroups &&
              accommodationGroups.length &&
              accommodationGroups.map((accommodationGroup, index) => (
                <ReadMoreText
                  key={index}
                  seeMoreHeader={accommodationGroup.listTitle}
                  variant="fancy"
                  buttonTextObject={{
                    toOpen: { text: "See more", icon: "circle-plus" },
                    toClose: { text: "See less", icon: "circle-minus" },
                  }}
                  buttonTop
                  hideAll
                >
                  {accommodationGroup.accommodations.map(
                    ({ hotel, nightNumber }, index) => (
                      <HotelTile
                        key={index}
                        hotel={hotel}
                        nightNumber={nightNumber}
                      />
                    )
                  )}
                </ReadMoreText>
              ))}
          </>
        ),
        icon: "v2-thick-Tent",
      },
    ]
    if (
      tourHighlights &&
      tourHighlights.details &&
      tourHighlights.details.length
    ) {
      result.splice(2, 0, {
        title: "Tour Highlights",
        className: "highlights",
        content: (
          <>
            <h3 className="green-header">{`Highlights for ${title}`}</h3>
            <TourHighlightList highlights={tourHighlights.details} />
          </>
        ),
        icon: "v2-thick-Action-Camera",
      })
    }
    if (map && map.image?.title !== DUMMY_MAP_TITLE) {
      result.push({
        title: "Map",
        className: "map",
        content: (
          <ImageGallery
            variant="popup"
            images={[map.image]}
            open={mapIsOpen}
            onClose={() => setMapIsOpen(false)}
          />
        ),
        icon: "v2-thick-Map",
      })
    }
    result.push({
      title: `Reviews ${
        reviews.numReviews?.total ? `(${reviews.numReviews.total})` : ""
      }`,
      className: "tour-operator-reviews",
      content: (
        <>
          <h3 className="green-header">{`Reviews for ${title}`}</h3>
          <TourOperatorReviews
            reviews={reviews}
            operatorId={tourOperator.id}
            onReviewAdded={handleReviewAdded}
            loadReviews={
              hasMoreReviews && (
                <div className="load-review-button-container">
                  <Button
                    onClick={handleLoadMoreReviews}
                    color="outline-black"
                    size="full-width-large"
                  >
                    See More Reviews
                  </Button>
                </div>
              )
            }
          />
        </>
      ),
      icon: "v2-thick-Sun",
    })
    result.push({
      title: "Tour Booking Process",
      className: "tour-booking-process",
      content: <BookingProcess />,
      icon: "v2-thick-Signpost",
    })
    if (faqs && faqs.length) {
      result.push({
        title: "FAQ",
        className: "faq",
        content: (
          <>
            <h3 className="green-header">{`Frequently asked questions for ${title}`}</h3>
            {faqs.map((faq, index) => (
              <FAQTile key={index} faq={faq} />
            ))}
          </>
        ),
        icon: "v2-comments",
      })
    }
    if (tourForMe && tourForMe.details && tourDifficulty) {
      const difficultyLink = `<p>Read about our scale for <a href=${"/tour-difficulty-assessment/"}>Tour Difficulty Ratings.</a></p>`
      result.push({
        title: "Tour Difficulty",
        className: "difficulty",
        content: (
          <>
            <RatingBars
              rating={tourDifficulty}
              ariaLabel={`Difficulty rating of ${parseFloat(
                tourDifficulty
              )} out of 5`}
              variant="green"
            />
            <HtmlContent html={tourForMe.details.concat(difficultyLink)} />
          </>
        ),
        icon: "v2-thick-Fire",
      })
    }
    // Only show terms if worse cancellation
    if (terms && terms.worse) {
      result.push({
        title: "Non-Standard Cancellation Terms",
        className: "terms",
        content: (
          <>
            <h3 className="green-header">{`Non-standard Terms and Conditions for ${title}`}</h3>
            <p>
              If you need to cancel this tour, the following terms will apply
              depending on the date of cancellation:
            </p>
            <HtmlContent html={terms.text} />
          </>
        ),
        icon: "v2-cross-circle",
      })
    }
    return result
  }, [
    itinerary,
    bookingURL,
    priceHeader,
    dayofweekPrices,
    price,
    fixedDates,
    priceFooter,
    accommodationText,
    accommodationGroups,
    tourIncluded,
    faqs,
    map,
    mapIsOpen,
    reviews,
    handleLoadMoreReviews,
    hasMoreReviews,
    tourOperator,
    handleReviewAdded,
    seoText,
    tourForMe,
    tourDifficulty,
    tourHighlights,
    title,
    terms,
  ])

  const scrollToElement = elementName => {
    scroller.scrollTo(elementName, { smooth: true, offset: -125 })
  }

  const onRatingsClick = event => {
    event.preventDefault()
    scrollToElement("tour-operator-reviews")
    reviewsRef.current.click()
  }
  // Happiness Guarantee
  const handleHPClick = () => {
    setShowHP(prev => !prev)
  }
  const handleGTClick = () => {
    setShowGT(prev => !prev)
  }
  const handleLDClick = () => {
    setShowLD(prev => !prev)
  }
  const handleHPClose = useCallback(() => {
    setShowHP(false)
  }, [setShowHP])
  const handleGTClose = useCallback(() => {
    setShowGT(false)
  }, [setShowGT])
  const handleLDClose = useCallback(() => {
    setShowLD(false)
  }, [setShowLD])

  useEffect(() => {
    if (showHP) {
      openLayer({
        layerId: "happiness-promise",
        onClose: handleHPClose,
        level: 2,
      })
    } else {
      closeLayer("happiness-promise")
    }
  }, [showHP, openLayer, handleHPClose, closeLayer])
  useEffect(() => {
    if (showGT) {
      openLayer({
        layerId: "group-trips",
        onClose: handleGTClose,
        level: 2,
      })
    } else {
      closeLayer("group-trips")
    }
  }, [showGT, openLayer, handleGTClose, closeLayer])
  useEffect(() => {
    if (showLD) {
      openLayer({
        layerId: "lifetime-deposit-text",
        onClose: handleLDClose,
        level: 2,
      })
    } else {
      closeLayer("lifetime-deposit-text")
    }
  }, [showLD, openLayer, handleLDClose, closeLayer])

  // Tour Icons
  const revisedTourIcons = useMemo(() => {
    const result = [...tourIcons]

    if (groupTypes && groupTypes.nodes && groupTypes.nodes.length) {
      const tripType = result.find(item => item.icon === "distance")
      if (tripType && tripType.label) {
        groupTypes.nodes.forEach(type => (tripType.label += `, ${type.name}`))
      }
    }
    if (tourMonths && tourMonths.length) {
      result.splice(1, 0, {
        label: convertToMonths(tourMonths),
        icon: "v2-calendar",
      })
    }
    if (tourCategory) {
      result.splice(2, 0, {
        label: tourCategory.name,
        icon: TOUR_CATEGORY_ICONS[tourCategory.name] || "v2-calendar",
      })
    }
    if (tourDifficulty) {
      const label = TOUR_DIFFICULTY_OPTIONS.find(
        item => item.value === tourDifficulty
      ).text
      result.push({
        label: `${label} (${tourDifficulty}/5)`,
        icon: "v2-thick-Fire",
      })
    }
    return result
  }, [tourIcons, tourCategory, tourMonths, tourDifficulty, groupTypes])

  // Parent and grandparent
  const ancestors = useMemo(() => {
    const result = { text: "All Tours", url: "/tours/" }
    const { regionParent } = tourRegion
    if (regionParent && regionParent.node) {
      result.url = regionParent.node.uri
      result.text = regionParent.node.name
      if (
        regionParent.node.regionGrandparent &&
        regionParent.node.regionGrandparent.node
      ) {
        result.text += `, ${regionParent.node.regionGrandparent.node.name}`
      }
    }
    return result
  }, [tourRegion])
  // Bottom Seo
  let bottomSeoData = useMemo(() => {
    let result = []

    const { regionCategories, regionChildren, regionParent, ...region } =
      tourRegion

    const regionSiblings =
      regionParent &&
      regionParent.node &&
      regionParent.node.regionSiblings &&
      regionParent.node.regionSiblings.nodes.filter(
        sibling => sibling.name !== region.name
      )

    // Currently the 'region' is assigned to the parent so the 'relations' are one above what they should be.
    // Parent = Grandparent, region = parent, children = siblings (including self)
    // Example: A tour in Amalfi is actually assigned to Italy
    // This can be fixed by manually updated the primary region for tours in the back-end on 10a-tours.
    if (regionSiblings && regionSiblings.length) {
      const siblingRegions = regionSiblings.map(sibling => ({
        name: `${sibling.name} Tours`,
        link: sibling.uri,
      }))
      // const {
      //   node: { regionGrandparent },
      // } = regionParent

      // if (
      //   regionParent &&
      //   regionGrandparent &&
      //   regionGrandparent.node &&
      //   regionGrandparent.node.regionParentSiblings &&
      //   regionGrandparent.node.regionParentSiblings.nodes &&
      //   regionGrandparent.node.regionParentSiblings.nodes.length
      // ) {
      //   const parentSiblings = regionGrandparent.node.regionParentSiblings.nodes
      //     .filter(sibling => sibling.name !== regionParent.node.name)
      //     .map(sibling => ({
      //       name: `${sibling.name} Tours`,
      //       link: sibling.uri,
      //     }))
      //   result.push({
      //     title: `Tours in ${
      //       regionGrandparent.node.name
      //     } by ${getRegionChildTypeName(
      //       regionGrandparent.node.name,
      //       tourContinents
      //     )}`,
      //     backlink: {
      //       text: `Back to ${regionGrandparent.node.name} Tours`,
      //       link: regionGrandparent.node.uri,
      //     },
      //     items: parentSiblings,
      //   })
      // }
      result.push({
        title: `Tours in ${regionParent.node.name} by ${getRegionChildTypeName(
          regionParent.node.name,
          tourContinents
        )}`,
        backlink: {
          text: `Back to ${regionParent.node.name} Tours`,
          link: regionParent.node.uri,
        },
        items: siblingRegions,
      })
      // Other primary activity tours in region
      if (tourCategory) {
        // Only want the top-level activity. Ex: Bike for e-bike or road bike
        const primaryCategoryId = tourCategory.parentId || tourCategory.id
        const primaryCategory = regionCategories.nodes.find(
          category => category.id === primaryCategoryId
        )
        if (
          primaryCategory &&
          TOUR_REGION_CATEGORY_INCLUSION_IDS.includes(primaryCategory.id)
        ) {
          const siblingsWithSameCategory = regionSiblings
            .filter(
              sibling =>
                sibling.siblingRegionTourCategories &&
                sibling.siblingRegionTourCategories.nodes.length &&
                !!sibling.siblingRegionTourCategories.nodes.find(
                  category => category.id === primaryCategoryId
                ) &&
                !skipForBottomSeo.includes(
                  `/tours/${sibling.slug}-${primaryCategory.slug}/`
                )
            )
            .map(sibling => ({
              name: `${sibling.name} ${primaryCategory.name} Tours`,
              link:
                replaceURLs[
                  `/tours/${sibling.slug}-${primaryCategory.slug}/`
                ] || `/tours/${sibling.slug}-${primaryCategory.slug}/`,
            }))

          if (siblingsWithSameCategory && siblingsWithSameCategory.length) {
            result.push({
              title: `${primaryCategory.name} Tours in ${
                regionParent.node.name
              } by ${getRegionChildTypeName(
                regionParent.node.name,
                tourContinents
              )}`,
              backlink: {
                text: `Back to ${primaryCategory.name} Tours`,
                link: primaryCategory.uri.replace(/^\/activity\//, "/tours/"),
              },
              items: siblingsWithSameCategory,
            })
          }
        }
      }
    }
    // Other tours in parent
    if (regionChildren && regionChildren.nodes.length) {
      const siblingRegions = regionChildren.nodes.map(child => ({
        name: `${child.name} Tours`,
        link: child.uri,
      }))
      result.push({
        title: `Tours in ${region.name} by ${getRegionChildTypeName(
          region.name,
          tourContinents
        )}`,
        backlink: {
          text: `Back to ${region.name} Tours`,
          link: region.uri,
        },
        items: siblingRegions,
      })
    }

    // Tours by activity in parent
    if (regionCategories && regionCategories.nodes.length) {
      const filteredCategories = regionCategories.nodes.filter(
        category =>
          TOUR_REGION_CATEGORY_INCLUSION_IDS.includes(category.id) &&
          !skipForBottomSeo.includes(`/tours/${region.slug}-${category.slug}/`)
      )
      const categories =
        filteredCategories &&
        filteredCategories.map(category => ({
          name: `${category.name} Tours in ${region.name}`,
          link:
            replaceURLs[`/tours/${region.slug}-${category.slug}/`] ||
            `/tours/${region.slug}-${category.slug}/`,
        }))
      result.push({
        title: `${region.name} Tours by Activity `,
        backlink: {
          text: `Back to ${region.name} Tours`,
          link: region.uri,
        },
        items: categories,
      })
    }
    return result
  }, [tourRegion, tourCategory, tourContinents, skipForBottomSeo, replaceURLs])
  GTM.dataLayerPushPageInfo({
    template: "tour-single",
  })
  // Responsive Image Setting
  let featureImage = {}
  if (
    images &&
    images.feature &&
    images.feature.sizes &&
    images.feature.sizes.length
  ) {
    const { feature } = images
    const { alt, sizes } = feature
    featureImage.alt = alt
    let srcSet = ""
    const srcSetImages = sizes.filter(image =>
      image.name.includes("Tour Feature Image")
    )
    srcSetImages.forEach((image, index) => {
      if (index === 0) {
        featureImage.src = image.src
      }
      srcSet += `${image.src} ${image.width}w${
        index !== srcSetImages.length - 1 ? `,` : ""
      }`
    })
    const viewportWidth = "100vw - (100vw - 100%)"
    featureImage.sizes = `(max-width: 392px) calc(${viewportWidth} - 32px),(min-width:393px) calc(${viewportWidth} - 32px - 16px - 125px),(${screen.tablet.min}) calc(${viewportWidth} - 32px),(${screen.small.min}) calc(${viewportWidth} - 32px - 16px - 150px),(${screen.medium.min}) 615px, 755px`
    featureImage.srcSet = srcSet
  }

  // Structured Data
  const rawStructuredData = {
    name: title,
    description: pageSeo.metaDesc,
    ratings: reviews &&
      reviews.overall && {
        // Required - name, value, count
        name: "Overall Rating",
        value: reviews.overall,
        count: reviews.numReviews && reviews.numReviews.total,
        best: 5,
        worst: 0,
      },
    image: images && images.feature.src,
    price: price &&
      price.amount &&
      price.currency &&
      price.currency.code && {
        amount: price.amount,
        currency: price.currency.code,
        url: `${SITE_DOMAIN}/bookings/tour/${tourSlug}/`,
      },
    soldOut: !!(spotsRemaining === "Sold Out"),
  }
  const rawBreadcrumbStructuredData = headerBreadcrumbs.map(
    (breadcrumb, index) => {
      return {
        name: breadcrumb.text,
        url: `${SITE_DOMAIN}${breadcrumb.link}`,
        position: index + 1,
      }
    }
  )
  // Instant Booking
  useEffect(() => {
    if (!(currency && currency?.code === "USD")) {
      return setBookingURL(`/bookings/tour/${tourSlug}/`)
    }
    const instantBookingUri = instantBookings.find(item =>
      item.uri.includes(pageUri)
    )?.bookingUri
    if (instantBookingUri) {
      setBookingURL(instantBookingUri)
    }
  }, [pageUri, currency, tourSlug])
  return (
    <PrimaryLayout pagePath={pageUri} bottomSeoData={bottomSeoData}>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={generateMeta(pageUri, { ...pageSeo, metaRobotsNoindex: "index" })}
        structuredData={[
          generateBreadcrumbList(rawBreadcrumbStructuredData),
          generateProduct(rawStructuredData),
        ]}
      />
      <HappinessGuarantee
        handleClose={handleHPClose}
        className={showHP ? "" : "hidden"}
      />
      <LifetimeDeposit
        handleClose={handleLDClose}
        className={showLD ? "" : "hidden"}
      />
      <GroupTrips
        handleClose={handleGTClose}
        openForm={() => setIsGroupTripOpen(true)}
        className={showGT ? "" : "hidden"}
      />
      {AskQuestionModal}
      {GroupTripModal}
      <StickyBookingPriceCTA price={price} bookingURL={bookingURL} />
      <AboveFold>
        <BreadcrumbsV2 breadcrumbItems={headerBreadcrumbs} />
        <TourTitle
          title={title}
          subtitle={tourHighlights.subtitle || tourHighlights.title}
          reviewsSummary={{
            rating: reviews.overall,
            total: reviews.numReviews?.total,
          }}
          onRatingsClick={onRatingsClick}
          ancestors={ancestors}
          handleHPClick={handleHPClick}
        />
        <SingleTourHeader
          img={featureImage}
          gallery={images.gallery}
          mediaPartners={mediaPartners}
          spotsRemaining={spotsRemaining}
        />
        <div className="seen-in">
          <h2>As Seen In</h2>
          <div>
            {mediaPartners.map(partner => (
              <Image key={partner.title} {...partner.logoTile} />
            ))}
          </div>
        </div>
        <BookingPriceCTA
          price={price}
          bookingURL={bookingURL}
          askQuestion={
            <QuestionButton
              name="tour-ask-question"
              color="outline-black"
              size="full-width"
              onClick={() => setIsQuestionOpen(true)}
              $smallMax
            >
              Ask A Question
            </QuestionButton>
          }
          map={
            map &&
            map.image && (
              <ClickableMap onClick={() => setMapIsOpen(true)}>
                <Image {...map.image} />
                <span>Expand Map</span>
              </ClickableMap>
            )
          }
          terms={terms}
          handleLDClick={handleLDClick}
        />
        <AboutTour
          difficulty={tourDifficulty}
          tourDuration={tourDuration}
          tourIcons={revisedTourIcons}
        />
      </AboveFold>
      <VideoTestimonials variant="blue" />
      <WhyTravellers
        accommodations={
          <>
            <h3 className="green-header">{`Accommodation for ${title}`}</h3>
            <HtmlContent
              html={accommodationText.replace(/<h2>(.*?)<\/h2>/, "")}
              prices={{ showAbbr: "after-full-size" }}
            />
            {accommodationGroups &&
              accommodationGroups.length &&
              accommodationGroups.map((accommodationGroup, index) => (
                <ReadMoreText
                  key={index}
                  seeMoreHeader={accommodationGroup.listTitle}
                  variant="fancy"
                  buttonTextObject={{
                    toOpen: { text: "See more", icon: "circle-plus" },
                    toClose: { text: "See less", icon: "circle-minus" },
                  }}
                  buttonTop
                  hideAll
                >
                  {accommodationGroup.accommodations.map(
                    ({ hotel, nightNumber }, index) => (
                      <HotelTile
                        key={index}
                        hotel={hotel}
                        nightNumber={nightNumber}
                        lazyload={false}
                      />
                    )
                  )}
                </ReadMoreText>
              ))}
          </>
        }
        inclusions={tourIncluded}
      />
      <GroupTripTile display="small-max" handleOpen={handleGTClick} />
      <BelowFold>
        <AccordionContent>
          <h2 className="green-header">{`${title.replace(
            /\s?Tour$/i,
            ""
          )} Tour Details`}</h2>
          {tabData && tabData.length
            ? tabData.map(tab => {
                const { className, title, content, icon } = tab
                return (
                  <CollapsibleContent
                    key={className}
                    header={
                      <h3 className="accordion-header">
                        {icon && <Icon glyph={icon} />}
                        {title}
                      </h3>
                    }
                    content={content}
                    className={className}
                    ref={
                      className === "dates"
                        ? datesRef
                        : className === "tour-operator-reviews"
                        ? reviewsRef
                        : null
                    }
                    defaultOpen={false}
                    handleClick={
                      title === "Map" ? () => setMapIsOpen(true) : null
                    }
                    icon={title === "Map" ? false : "v2-angle-down"}
                  />
                )
              })
            : undefined}
        </AccordionContent>
        <BookingCTA
          price={price}
          bookingURL={bookingURL}
          groupTrip={<GroupTripTile handleOpen={handleGTClick} />}
          askQuestion={
            <QuestionButton
              name="tour-ask-question"
              color="outline-black"
              size="full-width"
              onClick={() => setIsQuestionOpen(true)}
            >
              Ask A Question
            </QuestionButton>
          }
        />
        {/* <Button onClick={handleGTClick}>Open or close group trips</Button> */}
      </BelowFold>
      {relatedTours && relatedTours.length && (
        <RelatedToursSection>
          <h2>Related Tours</h2>
          <TourList tours={relatedTours} />
        </RelatedToursSection>
      )}
      <SocialProofReasons />
      <SocialProofMediaPartners />
      <TenAQuote />
    </PrimaryLayout>
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.number,
    pageUri: PropTypes.string,
    skipForBottomSeo: PropTypes.arrayOf(PropTypes.string),
    replaceURLs: PropTypes.object,
  }),
  data: PropTypes.shape({
    tenTours: PropTypes.shape({
      tour: PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
        spotsRemaining: PropTypes.string,
        images: PropTypes.shape({
          feature: PropTypes.shape({ ...ImagePropTypes }),
          gallery: PropTypes.arrayOf(
            PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
            })
          ),
        }),

        map: PropTypes.shape({
          image: PropTypes.shape({
            ...ImagePropTypes,
            title: PropTypes.string,
          }),
        }),
        tourHighlights: PropTypes.shape({
          details: PropTypes.arrayOf(
            PropTypes.shape({
              details: PropTypes.string,
            })
          ),
        }),
        seoText: PropTypes.string,
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
        groupTypes: PropTypes.shape({
          nodes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
        }),
        tourDifficulty: PropTypes.number,
        price: PropTypes.shape({
          ...PricePropTypes,
        }),
        priceHeader: PropTypes.string,
        priceFooter: PropTypes.string,
        dayofweekPrices: PropTypes.shape({
          footer: PropTypes.string,
          prices: PropTypes.arrayOf(
            PropTypes.shape({
              day: PropTypes.string,
              price: PropTypes.shape({ ...PricePropTypes }),
            })
          ),
        }),
        fixedDates: PropTypes.arrayOf(
          PropTypes.shape({
            duration: PropTypes.number,
            openings: PropTypes.number,
            start: PropTypes.shape({
              date: PropTypes.string,
              location: PropTypes.string,
            }),
            finish: PropTypes.shape({
              date: PropTypes.string,
              location: PropTypes.string,
            }),
            price: PropTypes.shape({ ...PricePropTypes }),
          })
        ),
        tourDuration: PropTypes.shape({
          days: PropTypes.number,
          nights: PropTypes.number,
        }),
        tourIcons: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            icon: PropTypes.string,
          })
        ),
        tourForMe: PropTypes.shape({
          details: PropTypes.string,
          title: PropTypes.string,
        }),
        tourMonths: PropTypes.arrayOf(PropTypes.number),
        itinerary: PropTypes.shape({
          title: PropTypes.string,
          days: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              title: PropTypes.string,
              details: PropTypes.string,
              attributes: PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string,
                  value: PropTypes.string,
                })
              ),
            })
          ),
        }),
        accommodationText: PropTypes.string,
        accomodationGroups: PropTypes.arrayOf(
          PropTypes.shape({
            listTitle: PropTypes.string,
            accommodations: PropTypes.shape({
              nightNumber: PropTypes.string,
              hotel: PropTypes.shape({
                ...HotelTilePropTypes,
              }),
            }),
          })
        ),
        tourIncluded: PropTypes.string,
        faqs: PropTypes.arrayOf(
          PropTypes.shape({
            question: PropTypes.string,
            answer: PropTypes.string,
          })
        ),
        tourOperator: PropTypes.shape({
          name: PropTypes.string,
          uri: PropTypes.string,
          operatorTnC: PropTypes.shape({
            lifetime: PropTypes.bool,
            worse: PropTypes.bool,
            text: PropTypes.string,
          }),
        }),
        tourTnC: PropTypes.shape({
          lifetime: PropTypes.bool,
          worse: PropTypes.bool,
          text: PropTypes.string,
        }),
        tourCategory: PropTypes.shape({
          name: PropTypes.string,
          uri: PropTypes.string,
        }),
        tourRegion: PropTypes.shape({
          name: PropTypes.string,
          uri: PropTypes.string,
        }),

        relatedTours: PropTypes.shape({
          ...TourDetailTilePropTypes,
        }),
      }),
    }),
  }),
}

export default PageTemplate

export const query = graphql`
  query TourDetailQuery($id: ID!, $currency: TenToursGQL_CurrencyCodeEnum) {
    tenTours {
      tour(idType: DATABASE_ID, id: $id) {
        title
        slug
        spotsRemaining
        images {
          feature {
            ...TenToursGQL_ImageSizesQuery
            sizes {
              name
              src: url
              width
            }
          }
          gallery {
            alt
            src: url
            sizes {
              name
              src: url
            }
          }
        }
        map {
          image {
            ...TenToursGQL_ImageSizesQuery
            title
          }
        }
        tourHighlights {
          title
          subtitle
          details {
            details
          }
        }
        seoText
        seo {
          ...TenToursGQL_PostSEO
        }
        groupTypes {
          nodes {
            name
          }
        }
        tourDifficulty
        price(currency: $currency) {
          ...PriceFragment
        }
        priceHeader
        priceFooter
        dayofweekPrices {
          footer
          prices {
            day
            price(currency: $currency) {
              ...PriceFragment
            }
          }
        }
        fixedDates {
          duration
          openings
          start {
            date
            location
          }
          finish {
            date
            location
          }
          price(currency: $currency) {
            ...PriceFragment
          }
        }
        tourDuration {
          days
          nights
        }
        tourIcons {
          label
          icon
        }
        tourForMe {
          details
          title
        }
        itinerary(format: HTML) {
          title
          days {
            label
            title
            details
            attributes {
              key
              value
            }
          }
        }
        accommodationText
        accommodationGroups {
          listTitle
          accommodations {
            nightNumber
            hotel {
              ...TenToursGQL_HotelTileQuery
            }
          }
        }
        tourIncluded
        faqs(format: HTML) {
          question
          answer
        }
        tourOperator {
          id: databaseId
          name: title
          reviews {
            overall: overall_rating
            counts {
              total
              five
              four
              three
              two
              one
            }
          }
          operatorTnC: termsConditions {
            lifetime
            text
            worse
          }
        }
        tourTnC {
          lifetime
          text
          worse
        }
        tourCategory {
          id: databaseId
          name
          uri
          parentId: parentDatabaseId
        }
        tourMonths
        tourRegion {
          id: databaseId
          name
          slug
          uri
          regionCategories: tourCategories(where: { parent: 0 }, first: 100) {
            nodes {
              id: databaseId
              name
              slug
              uri
            }
          }
          regionParent: parent {
            node {
              name
              uri
              regionGrandparent: parent {
                node {
                  name
                  uri
                  regionParentSiblings: children(first: 100) {
                    nodes {
                      name
                      uri
                    }
                  }
                }
              }
              regionSiblings: children(first: 100) {
                nodes {
                  name
                  slug
                  uri
                  siblingRegionTourCategories: tourCategories(first: 100) {
                    nodes {
                      id: databaseId
                    }
                  }
                }
              }
            }
          }
          regionChildren: children(first: 100) {
            nodes {
              name
              uri
            }
          }
        }

        relatedTours {
          ...TenToursGQL_TourDetailTileQuery
        }
      }
    }
  }
`
