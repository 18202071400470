import React, { useState } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Image from "@src/components/core-image"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"
import YoutubeModal from "@src/components/youtube-player-modal"
import RatingStars from "@src/components/core-rating-stars"

const ClickableTile = styled(Link)`
  position: relative;
  width: ${props =>
    props.variant === "plain"
      ? "225px"
      : props.variant === "why10A"
      ? "312px"
      : "384px"};
  text-decoration: none;
  border-radius: 8px;
  ${props =>
    !["plain", "why10A"].includes(props.variant)
      ? "border: 1px solid gray;"
      : null}
  & > div {
    position: relative;
    width: ${props =>
      props.variant === "plain"
        ? "225px"
        : props.variant === "why10A"
        ? "312px"
        : "383px"};
    &.fixed-height {
      background: ${props => props.theme.white};
      width: 382px;
    }
    & img {
      border-radius: ${props =>
        props.variant === "plain" || props.variant === "why10A"
          ? "8px"
          : "8px 8px 0px 0px"};
      ${props =>
        props.variant === "why10A" ? null : "filter: brightness(70%);"}
    }
    color: ${props => props.theme.white};
    border-radius: ${props =>
      props.variant === "plain" || props.variant === "why10A"
        ? "8px"
        : "8px 8px 0px 0px"};
    :hover {
      color: ${props => props.theme.primary};
    }

    & > i {
      position: absolute;
      bottom: ${props => (props.variant === "plain" ? "12px" : "35px")};
      left: ${props => (props.variant === "plain" ? "12px" : "20px")};
      font-weight: 700;
      ${props =>
        props.variant === "why10A"
          ? css`
              background: ${props.theme.white};
              color: ${props.theme.black};
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              :hover {
                border: 3px solid ${props.theme.primary};
              }
            `
          : null}
    }

    & p {
      position: absolute;
      margin: 0;
      right: 12px;
      bottom: 25px;
      font-family: system-ui;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      &.why10a-video-text {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        right: 50%;
        transform: translateX(50%);
      }
    }
    & > div.details {
      position: absolute;
      margin: 0;
      right: 20px;
      bottom: 20px;
      max-width: 75%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & > p {
        position: relative;
        right: auto;
        bottom: auto;
        text-align: right;
        &.name {
          font-weight: 700;
        }
        &.tour {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }

      & .ratings {
        font-family: system-ui;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
  }
  & .quote {
    font-family: system-ui;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    margin: 0;
    padding: 20px;
    width: ${props => (props.variant === "plain" ? "225px" : "382px")};
    &.fixed-height {
      height: 160px;
    }
    background: ${props => props.theme.white};
    border-radius: 0px 0px 8px 8px;
  }

  ${props =>
    props.variant === "why10A"
      ? css`
          @media only screen and (${props => props.theme.screen.mobile.max}) {
            width: 290px;
            & > div {
              width: 290px;
            }
          }
        `
      : props.variant !== "plain"
      ? css`
          @media only screen and (max-width: 420px) {
            width: 320px;
            & > div,
            & > div.fixed-height {
              width: 318px;
            }
            & .quote {
              width: 318px;
            }
          }
        `
      : null}
`

const VideoTestimonialTile = ({
  name,
  url,
  image,
  variant,
  tour,
  quote,
  style,
}) => {
  const [showModal, setShowModal] = useState(false)
  const handleModalClose = () => setShowModal(false)
  const imageDimensions = {
    plain: { width: 225, height: 187 },
    portrait: { width: 384, height: 455 },
    landscape: { width: 384, height: 221 },
    why10A: { width: 312, height: 216 },
  }
  return (
    <>
      <ClickableTile
        className="video-testimonial "
        variant={variant}
        onClick={event => {
          event.preventDefault()
          setShowModal(true)
        }}
      >
        <div className={style ? style : ""}>
          <Image
            {...image}
            height={imageDimensions[variant].height}
            width={imageDimensions[variant].width}
          />
          <Icon
            glyph={variant === "why10A" ? "v2-solid-play" : "v2-play"}
            iconFontSize={variant === "plain" ? "20px" : "26px"}
          />
          {variant === "plain" ? (
            <p>{name}</p>
          ) : variant === "why10A" ? (
            <p className="why10a-video-text">Hear from our Travellers</p>
          ) : (
            (name || tour) && (
              <div className={`details`}>
                {name && <p className="name">{name}</p>}
                {tour && <p className="tour">{tour}</p>}
                <RatingStars value={5} />
              </div>
            )
          )}
        </div>
        {variant !== "plain" && quote && (
          <p className={`quote ${style ? style : ""}`}>{`"${quote}"`}</p>
        )}
      </ClickableTile>

      {showModal ? (
        <YoutubeModal
          id={`${name}-video`}
          isOpen={showModal}
          onClose={handleModalClose}
          videoId={url.slice(-11)}
        />
      ) : null}
    </>
  )
}

export default VideoTestimonialTile

VideoTestimonialTile.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    srcset: PropTypes.string,
    lazyload: PropTypes.bool,
  }),
  variant: PropTypes.oneOf(["plain", "portrait", "landscape", "why10A"]),
  style: PropTypes.oneOf(["fixed-height"]),
  tour: PropTypes.string,
  quote: PropTypes.string,
}

VideoTestimonialTile.defaultProps = {
  variant: "plain",
}
