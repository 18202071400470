/*
AggregateRating:
  - Google: https://developers.google.com/search/docs/advanced/structured-data/review-snippet#json-ld
  - Schema.org: https://schema.org/AggregateRating
*/
const generateAggregateRating = ratings => {
  return {
    "@type": "AggregateRating",
    name: ratings.name,
    ratingValue: ratings.value,
    reviewCount: ratings.count,
    worstRating: ratings.worst,
    bestRating: ratings.best,
  }
}

export default generateAggregateRating
