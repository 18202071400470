import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { htmlToJsx } from "@src/components/core-value-html"
import Button from "@src/components/core-button"
import { FancyListBullets } from "@src/styles/elements"
const FAQ = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & > .faq-question {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    & > * {
      color: #3a6968; // Natasha design
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.25px;
    }
    & > p {
      margin-bottom: 8px;
    }
    &:hover {
      cursor: pointer;
      & > * {
        color: #41c689; // Natasha design
      }
    }
    & > button {
      padding: 2px 0px;
      margin-right: 10px;
      transition: transform 200ms;
      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  // Answer
  & > * {
    display: none;
  }
  &.show-answer > *:not(.faq-question) {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0 0 1em 24px;
  }
  &.show-answer {
    & ul {
      ${FancyListBullets}
    }
    & a {
      color: inherit;
      text-decoration: underline;
      font-weight: 500;
    }
  }
`
const FAQTile = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false)
  const question = faq.question.replace("h3", "p")
  return (
    <FAQ className={`${!isOpen ? "" : "show-answer"}`}>
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <Button
          className={isOpen ? "rotated" : ""}
          variant="icon"
          icon={isOpen ? "circle-minus" : "circle-plus"}
        />
        {htmlToJsx(question)}
      </div>
      {htmlToJsx(faq.answer)}
    </FAQ>
  )
}

FAQTile.propTypes = {
  faq: PropTypes.object,
}

export default FAQTile
