export const BOOKING_STEPS = [
  {
    icon: "✅",
    text: "Submit Booking",
  },
  {
    icon: "🗓️",
    text: "Confirm Availability",
  },
  {
    icon: "💵",
    text: "Pay Deposit",
  },
  {
    icon: "🎉",
    text: "Trip Confirmed!",
  },
]
