import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Currency from "@src/components/core-value-local-currency"
import Button from "@src/components/core-button"
import Icon from "@src/components/core-icon"
import { PricePropTypes } from "@src/graphql-fragments/prices"

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  & button:disabled {
    background-color: #d82121; // Add to V2 theme with design guide change
    &:not(.tablet-show) {
      width: 130.65px; // To match Book Now since one is <a> and other is <button>
    }
  }

  & thead {
    & tr {
      height: 40px;
      & th {
      }
    }
  }
  & tbody {
    & tr {
      height: 110px;
      position: relative;
      &:not(:only-child) {
        border-top: 1px solid black;
      }
      & td {
        text-align: center;
        height: inherit;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.mobile.max}) {
    & tbody {
      & tr {
        & td {
          vertical-align: top;
        }
      }
    }
  }
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    & tbody {
      & tr {
        height: 100px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.tablet.max}) {
    .tablet-hide {
      display: none;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    .tablet-show {
      display: none;
    }
    & tbody {
      & tr {
        height: 70px;
      }
    }
  }
`
const DateAndPlaceDiv = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & p {
      margin-bottom: 0;
    }
  }
`
const PriceAndButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const DateP = styled.p`
  font-weight: 500;
`
const DurationAndOpeningsP = styled.p`
  @media only screen and (${props => props.theme.screen.mobile.max}) {
    position: absolute;
    bottom: 1px;
  }
`
const StyledCurrency = styled(Currency)`
  font-weight: bold;
`

const FixedDatePricesTable = ({ fixedDateData, className, bookingURL }) => {
  const fixedDateTrips =
    fixedDateData &&
    fixedDateData.reduce((result, fixedDateTrip, index) => {
      const { start, finish, duration, price } = fixedDateTrip
      if (start && start.date) {
        // check for expired start dates
        const startTimestamp = Date.parse(`${start.date}T00:00:00.000Z`)
        if (!isNaN(startTimestamp) && startTimestamp <= Date.now()) {
          return result
        }
      }
      const openings =
        fixedDateTrip.openings === null ? 0 : fixedDateTrip.openings
      const isBookingOpen = !!openings
      result.push(
        <tr key={index}>
          <td>
            <DateAndPlaceDiv>
              <DateP>{start.date}</DateP>
              <p>{start.location}</p>
              <DurationAndOpeningsP className="tablet-show">
                {`${duration} days / `}{" "}
                {`${openings && openings >= 5 ? "5+" : openings} Spaces Left`}
              </DurationAndOpeningsP>
            </DateAndPlaceDiv>
          </td>
          <td>
            <Icon glyph="arrow-right" iconFontSize="20px" />
          </td>
          <td>
            <DateAndPlaceDiv>
              <DateP>{finish.date}</DateP>
              <p>{finish.location}</p>
            </DateAndPlaceDiv>
          </td>
          <td className="tablet-hide">{duration}</td>
          <td className="tablet-hide">{`${
            openings && openings >= 5 ? "5+" : openings
          }`}</td>
          <td>
            <PriceAndButtonDiv>
              <StyledCurrency
                amount={price?.amount}
                code={price?.currency?.code}
                showAbbr="after"
              />
              <Button
                name="tour-book-now-fixed"
                className="tablet-show"
                color="yellow-black"
                variant="rectangle"
                href={isBookingOpen ? bookingURL : null}
                mobileSizeOnly
                rel="nofollow"
                external
                disabled={!isBookingOpen}
                generateDataLayer={() => ({
                  "tour-fixed-date": start.date,
                })}
              >
                {`${isBookingOpen ? "Book Now" : "Sold Out"}`}
              </Button>
            </PriceAndButtonDiv>
          </td>
          <td className="tablet-hide">
            <Button
              name="tour-book-now-fixed"
              color="yellow-black"
              variant="rectangle"
              mobileSizeOnly
              href={isBookingOpen ? bookingURL : null}
              rel="nofollow"
              external
              disabled={!isBookingOpen}
              generateDataLayer={() => ({
                "tour-fixed-date": start.date,
                "highlight-tile": "Dates & Prices",
              })}
            >
              {`${isBookingOpen ? "Book Now" : "Sold Out"}`}
            </Button>
          </td>
        </tr>
      )
      return result
    }, [])

  return (
    <>
      <StyledTable className={className}>
        <thead>
          <tr>
            <th>Start</th>
            {/* Below is for the arrow */}
            <th />
            <th>Finish</th>
            <th className="tablet-hide">Duration</th>
            <th className="tablet-hide">Places Left</th>
            <th>Price</th>
            {/* Below is for book now button */}
            <th className="tablet-hide" />
          </tr>
        </thead>
        <tbody>{fixedDateTrips}</tbody>
      </StyledTable>
    </>
  )
}

FixedDatePricesTable.propTypes = {
  fixedDateData: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      openings: PropTypes.number,
      start: PropTypes.shape({
        date: PropTypes.string,
        location: PropTypes.string,
      }),
      finish: PropTypes.shape({
        date: PropTypes.string,
        location: PropTypes.string,
      }),
      price: PropTypes.shape({ ...PricePropTypes }),
    })
  ),
  className: PropTypes.string,
  bookingURL: PropTypes.string,
}

export default FixedDatePricesTable
