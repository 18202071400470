import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { htmlToJsx } from "./core-value-html"

const ItineraryDiv = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(4, auto);
  column-gap: 12px;

  & > .number {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: ${props => props.theme.white};
    background: ${props => props.theme.black};
  }
  & > .solid-line {
    grid-column: 1 / 2;
    grid-row: 2 / 5;
    margin: 0 auto;
    width: 3px;
    background: ${props => props.theme.black};
  }
  & > .day-tag {
    grid-column: 2 / 3;
    grid-row: 1/ 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: fit-content;
    padding: 0 16px;
    border-radius: 8px;
    background: #f4f4f4; // Natasha Design
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  & > .day-title {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.25px;
    margin: 10px 0;
  }
  & > .day-attributes {
    grid-column: 2 / 3;
    grid-row: 3/ 4;
    color: #737373;
    font-family: system-ui;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.25px;
    margin-bottom: 10px;
  }
  & > .day-text {
    grid-column: 2 / 3;
    grid-row: 4/ 5;
    margin: 10px 0 10px 0;
  }
  &:last-of-type {
    & > .solid-line {
      display: none;
    }
  }
`

const ItineraryDayTile = ({ itinerary }) => {
  let attributes = ""
  if (itinerary.attributes && itinerary.attributes.length)
    itinerary.attributes.forEach((attribute, index) => {
      attributes += `${index > 0 ? ` |` : ""} ${attribute.key} - ${
        attribute.value
      }`
    })
  return (
    <ItineraryDiv>
      <div className="number">{itinerary.label.split(" ")[1]}</div>
      <span className="solid-line" />
      <div className="day-tag">{itinerary.label}</div>
      <h4 className="day-title">{itinerary.title}</h4>
      {attributes && attributes.length && (
        <span className="day-attributes">{attributes}</span>
      )}
      <div className="day-text">{htmlToJsx(itinerary.details)}</div>
    </ItineraryDiv>
  )
}
ItineraryDayTile.propTypes = {
  itinerary: PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
}

export default ItineraryDayTile
