import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { LocaleContext } from "@src/context/locale-context"
import { testimonialPageData } from "@src/utils/constants/video-testimonials"
import VideoTestimonialTile from "@src/components/content-social-proof-video-testimonial-tile"
import ContentCarousel from "@src/components/container-carousel"
import Button from "@src/components/core-button"

const VideoTestimonialContainer = styled.div`
  margin: 0 0 64px;
  color: #26383b; // Natasha Design
  ${props =>
    props.variant === "blue"
      ? css`
          background: #26383b; // Natasha Design
          color: ${props.theme.white};
          padding: 32px 16px;
          margin: 16px 0 0;
          & .lazyload-wrapper {
            border-radius: 8px;
            border: 2px solid white;
          }
        `
      : ""}
  & .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    & h2 {
      font-family: system-ui;
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: 0.5px;
      text-align: left;
      margin: 0 0 16px;
    }
  }
  & .carousel-container {
    width: 100%;
    & .prev {
      left: -14px;
    }
    & .next {
      right: -14px;
    }
  }
  @media only screen and (${props => props.theme.screen.mobile.max}) {
    & .text-container {
      & a {
        padding: 12px 0;
        width: 100%;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    & .text-container {
      & h2 {
        text-align: center;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & .text-container {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      & h2 {
        width: 520px;
        text-align: left;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    margin-top: 32px;
    justify-content: center;
    ${props =>
      props.variant === "blue"
        ? css`
            margin: 16px 0 0;
            padding: 96px 0;
          `
        : ""}
    & .text-container, & .carousel-container {
      width: 880px;
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    & .text-container,
    & .carousel-container {
      width: 1170px;
    }
  }
`
const Wave = styled.div`
   {
    display: block;
    position: relative;
    margin-top: -5px;
    height: 80px;
    width: 100%;
    background: #26383b;
    z-index: 1;
    // Source: https://stackoverflow.com/questions/61157568/create-clip-path-wave-css-edges
    // Use: https://wave.novoselski.net/ Frequency 0.9, Amplitude 70, Phase 140, Points 50, Invert True
    clip-path: polygon(
      100% 0%,
      0% 0%,
      0% 48.79%,
      2% 47.21%,
      4% 45.88%,
      6% 44.82%,
      8% 44.03%,
      10% 43.54%,
      12% 43.34%,
      14% 43.44%,
      16% 43.83%,
      18% 44.52%,
      20% 45.49%,
      22% 46.73%,
      24% 48.22%,
      26% 49.95%,
      28% 51.89%,
      30% 54.03%,
      32% 56.32%,
      34% 58.75%,
      36% 61.28%,
      38% 63.87%,
      40% 66.5%,
      42% 69.14%,
      44% 71.74%,
      46% 74.28%,
      48% 76.72%,
      50% 79.03%,
      52% 81.19%,
      54% 83.15%,
      56% 84.91%,
      58% 86.44%,
      60% 87.71%,
      62% 88.71%,
      64% 89.43%,
      66% 89.87%,
      68% 90%,
      70% 89.84%,
      72% 89.38%,
      74% 88.63%,
      76% 87.6%,
      78% 86.3%,
      80% 84.75%,
      82% 82.97%,
      84% 80.98%,
      86% 78.81%,
      88% 76.48%,
      90% 74.03%,
      92% 71.49%,
      94% 68.88%,
      96% 66.24%,
      98% 63.61%,
      100% 61.02%
    );
  }
`
const VideoTestimonials = ({ variant }) => {
  // const [showVideo, setShowVideo] = useState(false)
  // const handleVideoClose = () => setShowVideo(false)
  const { country } = useContext(LocaleContext)

  return (
    <>
      <VideoTestimonialContainer variant={variant}>
        <div className="text-container">
          <h2>What travellers are saying about 10Adventures</h2>
          <Button
            // onClick={() => setShowVideo(true)}
            to="/testimonials/"
            color="yellow-black"
            external
          >
            View All Testimonials
          </Button>
        </div>
        <ContentCarousel
          buttonType="circle"
          buttonColor="white-gray"
          mobileSizeOnly
          icons={{
            left: "v2-arrow-small-left",
            right: "v2-arrow-small-right",
          }}
        >
          {testimonialPageData
            .filter(testimonial =>
              ["GB", "CA", "AU", "US"].includes(country)
                ? testimonial.country && testimonial.country.includes(country)
                : testimonial.carousel
            )
            .sort(() => Math.random() - 0.5)
            .map(({ name, url, tour, quote, imageFolder }) => {
              const imageURLs = ["384x221", "768x442"].map(size =>
                encodeURI(`${imageFolder}${size}.jpg`)
              )
              const testimonial = {
                name,
                url,
                tour,
                quote,
                variant: "landscape",
                style: "fixed-height",
                image: {
                  src: imageURLs[0],
                  alt: `${name}-photo`,
                  srcSet: `${imageURLs[0]} 1x, ${imageURLs[1]} 2x`,
                },
              }
              return (
                <VideoTestimonialTile key={testimonial.name} {...testimonial} />
              )
            })}
        </ContentCarousel>
      </VideoTestimonialContainer>
      {variant === "blue" ? <Wave /> : null}
      {/* {showVideo ? (
        <YoutubeModal
          id="why10a-video"
          isOpen={showVideo}
          onClose={handleVideoClose}
          videoId={why10ATestimonial.url.slice(-11)}
        />
      ) : null} */}
    </>
  )
}

export default VideoTestimonials

VideoTestimonials.propTypes = {
  variant: PropTypes.oneOf(["default", "blue"]),
}
VideoTestimonials.defaultProps = {
  variant: "default",
}
