import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import Link from "@src/components/core-link"

const TourTitleContainer = styled.div`
  width: 100%;
  font-family: "system-ui";
  margin-bottom: 16px;
  h1 {
    font-family: system-ui;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-top: 0px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  & .core-icon-content {
    align-items: center;
    margin-bottom: 5px;
    color: #979797;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.005em;
    text-align: left;
    & i {
      margin-right: 5px;
    }
    & > a {
      color: #979797;
      :hover {
        color: black;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & .core-icon-content {
      display: inline-flex;
      position: relative;
      &:not(:first-of-type) {
        padding-left: 30px;
        &:before {
          position: absolute;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: ${props => props.theme.black};
          left: 15px;
        }
      }
    }
  }
`

const TourTitle = ({
  title,
  subtitle,
  reviewsSummary,
  onRatingsClick,
  ancestors,
  handleHPClick,
}) => {
  return (
    <>
      <TourTitleContainer className="tour-title-container">
        <h1 className="title">{title}</h1>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        <div>
          <IconDetailContentTile glyph="star" color="primary">
            {
              <Link onClick={onRatingsClick}>
                {`${
                  reviewsSummary.total
                    ? `${reviewsSummary.rating.toFixed(1)} (${
                        reviewsSummary.total
                      } ${reviewsSummary.total == 1 ? "rating" : "ratings"})`
                    : "No ratings"
                }

            `}
              </Link>
            }
          </IconDetailContentTile>
          <IconDetailContentTile glyph="v2-marker" color="green">
            <Link to={ancestors.url}>{ancestors.text}</Link>
          </IconDetailContentTile>
          <IconDetailContentTile glyph="v2-badge" color="red">
            <Link onClick={handleHPClick}>Happiness Promise</Link>
          </IconDetailContentTile>
        </div>
      </TourTitleContainer>
    </>
  )
}

export default TourTitle

TourTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  reviewsSummary: PropTypes.shape({
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onRatingsClick: PropTypes.func,
  ancestors: PropTypes.shape({ url: PropTypes.string, text: PropTypes.string }),
  handleHPClick: PropTypes.func,
}
