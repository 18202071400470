import React, { useState, useCallback } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Button from "@src/components/core-button"
import Icon from "./core-icon"
import CollapsibleContent from "./core-collapsible-content"
import { SITE_DOMAIN } from "@src/utils/constants"
import Form, { FormActions } from "@src/components/form"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputEmail from "@src/components/form-field-input-email"
import FormFieldInput from "@src/components/form-field-input"
import FormFieldInputNumber from "@src/components//form-field-input-number"
import FormFieldInputPhoneNumber from "@src/components/form-field-input-phone-number"
import Spinner from "@src/components/core-spinner"

// Form
const StyledFormActions = styled(FormActions)`
  & > button {
    margin-bottom: 0px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    align-items: flex-start;
  }
`

const StyledForm = styled(Form)`
  & .hidden {
    display: none;
  }
  & ul {
    max-height: 130px;
    min-width: 280px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .narrow {
      width: 49%;
    }
    & > .full-width {
      width: 100%;
    }
    & > .left,
    & > ${StyledFormActions} {
      float: left;
    }
    & > .right {
      float: right;
    }
  }
`

export const GroupTripForm = ({ pageName, pagePath, operator, onClose }) => {
  const [formStatus, setFormStatus] = useState(null)
  const handleSuccessClick = () => {
    onClose()
    // Reset form so user can ask more questions
    setFormStatus(null)
  }
  const handleSubmit = useCallback(() => {
    setFormStatus({
      processing: "Please wait a moment while we save your message...",
    })
  }, [setFormStatus])
  const handleSubmitError = useCallback(
    error => {
      setFormStatus({ error: error.message })
    },
    [setFormStatus]
  )
  const handleSubmitSuccess = useCallback(
    result => {
      if (result.netlify) {
        setFormStatus({
          success:
            "Thanks for the message! Our team will get back to you soon.",
        })
      } else {
        handleSubmitError({
          message: "There was an error saving the message.",
        })
      }
    },
    [setFormStatus, handleSubmitError]
  )
  if (pagePath && !pagePath.startsWith(SITE_DOMAIN)) {
    pagePath = `${SITE_DOMAIN}${pagePath}`
  }
  const formName = "group-trip-zoho"
  return (
    <>
      {formStatus && formStatus.success ? (
        <>
          <p>{formStatus.success}</p>
          <Button onClick={handleSuccessClick}>Close</Button>
        </>
      ) : (
        <StyledForm
          name={formName}
          onSubmit={handleSubmit}
          onSubmitError={handleSubmitError}
          onSubmitSuccess={handleSubmitSuccess}
          netlify
        >
          <FormFieldInputText
            name={`${formName}-first-name`}
            label="First Name"
            placeholder="First name"
            validation={{
              required: "First name is required.",
            }}
            className="narrow left"
          />
          <FormFieldInputText
            name={`${formName}-last-name`}
            label="Last Name"
            placeholder="Last name"
            validation={{
              required: "Last name is required.",
            }}
            className="narrow right"
          />
          <FormFieldInputPhoneNumber
            id="phone-number"
            name="phone_number"
            label="Phone number"
            autoComplete="tel"
            className="full-width left"
          />
          <FormFieldInputNumber
            name={`${formName}-guest-count`}
            label="No. of guests"
            placeholder="1"
            min={1}
            validation={{
              required: "No. of guests required",
            }}
            className="narrow left"
          />
          <FormFieldInputEmail
            name={`${formName}-email`}
            placeholder="Email"
            label="Email"
            validation={{
              required: "Let us know where we can email a reply!",
            }}
            className="narrow right"
          />

          {/* Input below is to pass tour or operator name to netlify */}
          <FormFieldInput
            name={`${formName}-page-name`}
            value={pageName}
            className="hidden"
          />
          {operator && (
            <FormFieldInput
              name={`${formName}-operator-name`}
              value={operator}
              className="hidden"
            />
          )}
          {pagePath && (
            <FormFieldInput
              name={`${formName}-cms-link`}
              value={pagePath.replace(SITE_DOMAIN, "cms.10adventures.com")}
              className="hidden"
            />
          )}
          {pagePath && (
            <FormFieldInput
              name={`${formName}-page-url`}
              value={pagePath}
              className="hidden"
            />
          )}
          <StyledFormActions
            submitting={formStatus && formStatus.processing ? true : undefined}
          >
            {(formStatus?.error && (
              <p className="form-error">{formStatus.error}</p>
            )) ||
              (formStatus?.processing && (
                <p className="form-info">{formStatus.processing}</p>
              ))}
            <Button
              type="submit"
              disabled={formStatus && formStatus.processing ? true : undefined}
            >
              {formStatus?.processing ? (
                <Spinner color="white" size="sm" />
              ) : (
                "Send"
              )}
            </Button>
          </StyledFormActions>
        </StyledForm>
      )}
    </>
  )
}

// Side Panel
const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
  background: ${props => props.theme.bg1};
  z-index: 900;
  padding: 24px;
  font-family: system-ui;
  overflow-y: scroll;
  &.hidden {
    display: none;
  }
  & button,
  & li,
  & p {
    font-size: 16px;
    line-height: 24px;
  }
  & button {
    background: none;
    color: #667085;
    padding: 0;
    & .icon {
      font-size: 20px;
      margin-right: 4px;
      vertical-align: text-top;
    }
    &:hover {
      color: ${props => props.theme.black};
    }
  }

  & .green-header {
    font-family: system-ui;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: #3a6968; // Natasha design
    margin: 16px 0;
  }

  & .collapsible-div {
    margin-bottom: 18px;
    .collapsible-header {
      font-family: system-ui;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #1f1f23; // Natasha design
      margin-bottom: 18px;
      & > i {
        font-size: 20px;
      }
    }
  }

  & button.book-group-trip {
    justify-self: flex-end;
    width: 100%;
    height: 56px;
    padding: 16px 0;
    border-radius: 8px;
    background: #2b4346;
    color: ${props => props.theme.white};
    :hover {
      color: ${props => props.theme.primary};
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 40%;
    min-width: 320px;
    right: 0;
    box-shadow: -6px 0px 6px 0px rgba(61, 61, 61, 0.16);
  }
`
export const GroupTrips = ({ className, handleClose, openForm }) => (
  <Container className={`group-trips ${className ? className : ""}`}>
    <Button onClick={handleClose}>
      <Icon glyph="v2-arrow-small-left" />
      Back
    </Button>
    <p className="green-header">Group Trips</p>
    <p>
      Group travel can be chaotic and messy, which is why going on a trip with
      family or friends is the fun part and planning the trip, well… isn’t. But
      what if the group travel experience of your dreams could be organized
      without the hassle of you needing to plan every detail?
    </p>
    <p>
      With 10Adventures group tours, you’ll be the mastermind behind an
      unforgettable trip without the need to worry about logistics, payments, or
      who can get time off for a holiday.
    </p>
    <p>
      The VIP-level service of our Travel Advisors allows you to organize the
      adventure of your dreams, customize it exactly how you want to, and watch
      the savings roll in as more of your friends and family sign up for the
      once in a lifetime experience <strong>you</strong> created. It’s that
      simple!
    </p>
    <p>
      Take the first step on your next group adventure by contacting one of our
      Travel Advisors today.
    </p>
    <CollapsibleContent
      defaultOpen={false}
      header="Make Family Memories That Will Last Generations"
      content={
        <p>
          The window to travel as a family only narrows with time. Make the most
          of it by exploring the world together and creating memories that will
          last a lifetime.
        </p>
      }
      icon="v2-angle-down"
    />
    <CollapsibleContent
      defaultOpen={false}
      header="Share Your Mid-Life Crisis With Friends"
      content={
        <p>
          Why share a travel experience with people you don’t know? Spend your
          hard-earned vacation surrounded by close friends on the adventure of a
          lifetime.
        </p>
      }
      icon="v2-angle-down"
    />
    <CollapsibleContent
      defaultOpen={false}
      header="Build A Rock-Solid Team Culture Based On Shared Experiences, Not Office Politics"
      content={
        <p>
          Shared travel experiences strengthen bonds and are the perfect way to
          come together as colleagues to solve challenges in a way that just
          can’t be done in an office environment.
        </p>
      }
      icon="v2-angle-down"
    />
    <CollapsibleContent
      defaultOpen={false}
      header="Build Better Friendships Travelling The World"
      content={
        <p>
          Connect with members of your club or social circle over shared
          interests like food, culture, and the outdoors.
        </p>
      }
      icon="v2-angle-down"
    />
    <CollapsibleContent
      defaultOpen={false}
      header="Benefits of 10Adventures Group Tours"
      content={
        <ul className="primary">
          <li>
            <strong>VIP Service: </strong>Have a dedicated Travel Advisor who is
            always a call or a click away.
          </li>
          <li>
            <strong>Cost Savings: </strong>More people mean a lower cost per
            person. And there are additional discounts for children and shared
            rooms.
          </li>
          <li>
            <strong>Customizable: </strong>You are in charge and choose the
            start date, length, itinerary, accommodations, and other services.
          </li>
          <li>
            <strong>Easy Payment: </strong>Flexible payment schedule, and most
            importantly separate invoices so you aren’t left paying for other
            people.
          </li>
          <li>
            <strong>Private: </strong>Just you and your group making memories
            that will last your lifetime.
          </li>
          <li>
            <strong>Quality Time: </strong>There’s no better way to travel than
            alongside family and or friends. Enjoy quality time exploring the
            world with those you love most.
          </li>
        </ul>
      }
      icon="v2-angle-down"
    />
    {/* Create a form that has name, email, phone, number of guests, trip name */}
    <Button className="book-group-trip" onClick={openForm}>
      Request a quote
    </Button>
  </Container>
)

// Tile
const GroupTripDiv = styled.div`
  background: #497e7a;
  font-family: system-ui;
  padding: 40px 20px;
  border-radius: 8px;
  margin: ${props => (props.display === "small-max" ? "10px" : "10px 0")};
  & p {
    color: ${props => props.theme.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    &.group-trip-tile-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
  }
  & button {
    font-family: system-ui;
    font-size: 16px;
    font-weight: 700;
    background: ${props => props.theme.white};
    color: ${props => props.theme.black};
    :hover {
      color: ${props => props.theme.primary};
    }
  }
  ${props =>
    props.display === "small-max"
      ? css`
          @media only screen and (${props.theme.screen.medium.min}) {
            display: none;
          }
        `
      : ""}
`
export const GroupTripTile = ({ handleOpen, display }) => {
  return (
    <GroupTripDiv display={display}>
      <p className="group-trip-tile-title">Planning a group trip?</p>
      <p>
        Learn how we help group of friends, outdoor clubs and families get the
        tour they’ve been dreaming about.
      </p>
      <Button onClick={handleOpen} size="full-width">
        Learn More
      </Button>
    </GroupTripDiv>
  )
}

// PropTypes
GroupTripForm.propTypes = {
  pageName: PropTypes.string.isRequired,
  pagePath: PropTypes.string.isRequired,
  operator: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}
GroupTrips.propTypes = {
  openForm: PropTypes.func,
  className: PropTypes.string,
  handleClose: PropTypes.func,
}
GroupTripTile.propTypes = {
  handleOpen: PropTypes.func,
  display: PropTypes.oneOf(["small-max"]),
}
