import generateAggregateRating from "./generateAggregateRating"

/*
LocalBusiness:
  - Google: https://developers.google.com/search/docs/appearance/structured-data/product
  - Schema.org: https://schema.org/Product
*/

// Ref: https://stackoverflow.com/questions/38276672/todays-date-30-days-in-javascript
const createValidityDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 30)
  return date.toISOString().split("T")[0] // "2016-06-08"
}

const generateProduct = data => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: data.name,
  }
  if (data.ratings) {
    structuredData["aggregateRating"] = generateAggregateRating(data.ratings)
  }
  if (data.description) {
    structuredData["description"] = data.description
  }
  if (data.image) {
    structuredData["image"] = data.image
  }
  if (data.price) {
    structuredData["offers"] = {
      "@type": "Offer",
      price: data.price.amount,
      priceCurrency: data.price.currency,
      url: data.price.url,
      availability: data.soldOut
        ? "https://schema.org/SoldOut"
        : "https://schema.org/InStock",
      priceValidUntil: createValidityDate(),
    }
  }
  return structuredData
}

export default generateProduct
