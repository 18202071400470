import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "@src/components/core-icon"
const StyledRatingStars = styled.div`
  display: inline-block;
`
const Star = styled(Icon)`
  color: ${props =>
    props.variant === "black" ? props.theme.black : props.theme.primary};

  &.icon {
    font-size: ${props => (props.size === "condensed" ? "16px" : "19px")};
  }
  &:not(:first-child) {
    margin-left: 8px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    &.icon {
      font-size: ${props => (props.size === "condensed" ? "16px" : "21px")};
    }
    &:not(:first-child) {
      margin-left: 9px;
    }
  }
`
const RatingStars = ({ max, value, className, variant, size }) => {
  max = (max && parseFloat(max)) || 0
  value = (value && parseFloat(value)) || 0
  const ratingStars = []
  for (let i = 0; i < max; i++) {
    if (i < Math.floor(value)) {
      ratingStars.push(
        <Star key={i} glyph="star" variant={variant} size={size} />
      )
    } else if (i < Math.ceil(value)) {
      ratingStars.push(
        <Star key={i} glyph="star-half" variant={variant} size={size} />
      )
    } else {
      size !== "condensed" &&
        ratingStars.push(
          <Star key={i} glyph="star-o" variant={variant} size={size} />
        )
    }
  }
  return (
    <StyledRatingStars className={className}>{ratingStars}</StyledRatingStars>
  )
}
RatingStars.propTypes = {
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["black"]),
  size: PropTypes.oneOf(["condensed"]),
  className: PropTypes.string,
}
RatingStars.defaultProps = {
  max: 5,
}
export default RatingStars
