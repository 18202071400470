import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import Link from "@src/components/core-link"
import HtmlContent from "@src/components/core-value-html"
import RatingStars from "@src/components/core-rating-stars"

const StyledHotelArticle = styled.article`
  padding: 20px 15px;
  border: 1px solid ${props => props.theme.lighterGray};
  border-radius: 5px;
  margin: 0 0 40px 0;
  display: grid;
  grid-template-rows: 170px auto auto;
  font-size: 16px;

  h4 {
    margin-right: 10px;
    div {
      margin-left: 10px;
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-template-rows: auto;
    grid-template-columns: 193px auto;
    grid-column-gap: 30px;
  }
`
// This doesn't get any smaller at the moment becaue the parent container doesn't change sizes with design
const StyledImageWrapper = styled(ImageWrapper)`
  height: 170px;
  max-width: 640px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 193px;
    grid-row: 1/4;
    grid-column: 1/2;
  }
`

const NightTripDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0 20px 0;

  @media only screen and (${props => props.theme.screen.small.min}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const HotelStarsDiv = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-column: 2/3;
  }
  @media only screen and (${props =>
      props.theme.screen.medium.min}) and (${props =>
      props.theme.screen.medium.max}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  border: 1px solid ${props => props.theme.primary};
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 2px 10px;
  margin-top: 10px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: 0;
  }
`

const StyledHtmlContent = styled(HtmlContent)`
  p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-column: 2/3;
  }
`

const StyledRatingStars = styled(RatingStars)`
  i {
    vertical-align: baseline;
    @media only screen and (${props =>
        props.theme.screen.medium.min}) and (${props =>
        props.theme.screen.medium.max}) {
      vertical-align: middle;
    }
  }
`

const HotelTile = ({ hotel, nightNumber, lazyload }) => {
  const { title, content, tripadvisorUrl, ratingStars, images } = hotel
  return (
    <StyledHotelArticle>
      <Image
        placeholder
        wrapper={StyledImageWrapper}
        {...images.feature}
        lazyload={lazyload}
      />
      <NightTripDiv>
        <h4>Night {nightNumber}</h4>
        <StyledLink to={tripadvisorUrl}>See Tripadvisor Reviews</StyledLink>
      </NightTripDiv>
      <HotelStarsDiv>
        <h4>{title}</h4>
        <StyledRatingStars
          value={ratingStars}
          size="condensed"
          variant="black"
        />
      </HotelStarsDiv>
      <StyledHtmlContent html={content} />
    </StyledHotelArticle>
  )
}

export const HotelTilePropTypes = {
  hotel: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    tripadvisorUrl: PropTypes.string,
    ratingStars: PropTypes.number,
    images: {
      feature: PropTypes.shape({ ...ImagePropTypes }),
    },
  }),
  nightNumber: PropTypes.string,
}
HotelTile.propTypes = {
  hotel: PropTypes.shape(HotelTilePropTypes),
  nightNumber: PropTypes.string,
  lazyload: PropTypes.bool,
}
export default HotelTile

export const HotelTileQueries = graphql`
  fragment TenToursGQL_HotelTileQuery on TenToursGQL_Hotel {
    title
    tripadvisorUrl
    ratingStars
    content
    images {
      feature {
        ...TenToursGQL_ImageSizesQuery
      }
    }
  }
`
