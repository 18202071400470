import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Button from "@src/components/core-button"
import Icon from "./core-icon"
// Happiness Guarantee
const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
  background: ${props => props.theme.bg1};
  z-index: 900;
  padding: 24px;
  font-family: system-ui;
  overflow-y: scroll;

  &.hidden {
    display: none;
  }
  & button,
  & p {
    font-size: 16px;
    line-height: 24px;
  }
  & button {
    background: none;
    color: #667085;
    padding: 0;
    & .icon {
      font-size: 20px;
      margin-right: 4px;
      vertical-align: text-top;
    }
    &:hover {
      color: ${props => props.theme.black};
    }
  }

  & .red-header {
    font-family: system-ui;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: ${props => props.theme.red2};
    margin: 16px 0;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 40%;
    min-width: 320px;
    right: 0;
    box-shadow: -6px 0px 6px 0px rgba(61, 61, 61, 0.16);
  }
`

const HappinessGuarantee = ({ className, handleClose }) => (
  <Container className={`happiness-guarantee ${className ? className : ""}`}>
    <Button onClick={handleClose}>
      <Icon glyph="v2-arrow-small-left" />
      Back
    </Button>
    <p className="red-header">
      <Icon glyph="v2-badge" /> Happiness Promise
    </p>
    <p>
      There are hundreds of thousands of guides and tour operators around the
      world. Figuring out who to trust is stressful and hard.
    </p>
    <p>
      That’s why we started 10Adventures Tours, we’re here to make it easy to
      find a great guide or tour operator. We handpick the best local guides and
      tour operators for 10Adventures, working to ensure you have great guides
      and a great trip.
    </p>
    <p>
      We know it sucks to show up in a different country and find out the tents
      are junk, the guide incompetent or the route different from what we
      thought we were getting.
    </p>
    <p>
      We want to give our users peace of mind that you’ll have a great trip and
      offer a guarantee that we will work with you to make it right.
    </p>

    <p>
      <strong>How does this work?</strong>
      <br></br>
      Well, if you feel that something about your trip didn’t’ meet your
      expectations, let us know. We will then work to gather the details and
      figure out where things went wrong. If something indeed wasn’t as
      described, or went wrong with your trip, and the problem was within the
      control of either oursleves, the guide or the tour company, we will then
      work out a refund with you that’s proportional to the scale of the
      problem.
    </p>
    <p>
      Obviously the best way to make a great trip is to tell your local guide or
      tour operator and give them the chance to fix the solution while you are
      on your trip. Most things can be solved by telling your guide when the
      issue develops. If that doesn’t solve it, then email us right away. Please
      don’t wait to voice your concerns until the trip is over, as it’s much
      harder to solve issues after the fact.
    </p>
    <p>
      We work hard to make sure you have a great trip. If something isn’t right,
      please email 
      <a href="mailto:hello@10adventures.co">hello@10adventures.co</a> and we
      will get to work right away.
    </p>
  </Container>
)

export default HappinessGuarantee

HappinessGuarantee.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
}
