import React, { useState } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Button from "@src/components/core-button"

// Fix during typography polish. Ideally, p tag will not be established here but in the typography.css file.
const ReadMoreContainer = styled.div`
  display: grid;
  grid-column-template: auto;
  grid-row-template: auto auto;

  ${props =>
    props.hideAll
      ? css`
          :not(.open) > *:not(.read-more-actions) {
            display: none;
          }
        `
      : css`
          :not(.open) > div > p ~ *:not(.read-more-actions) {
            display: none;
          }
        `}
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.buttonTop &&
    css`
      grid-row: 1/2;
      margin-bottom: 20px;
    `}
`

const ToggleReadButton = ({ children, onClick, icon, color }) => {
  return (
    <Button
      iconRight={icon}
      color={color}
      size="small"
      onClick={onClick}
      mobileSizeOnly
      showMobileIcon
    >
      {children}
    </Button>
  )
}

const ReadMoreText = ({
  children,
  seeMoreHeader,
  buttonTextObject,
  buttonColor,
  hideAll,
  buttonTop,
  className,
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ReadMoreContainer
      hideAll={hideAll}
      className={`${className} ${isOpen && "open"}`}
    >
      {children}
      <ButtonDiv className="read-more-actions" buttonTop={buttonTop}>
        {seeMoreHeader && (
          <h4 className={`${variant === "fancy" ? "fancy" : ""}`}>
            {seeMoreHeader}
          </h4>
        )}
        <ToggleReadButton
          onClick={toggleOpen}
          icon={
            isOpen
              ? buttonTextObject.toClose.icon
              : buttonTextObject.toOpen.icon
          }
          color={buttonColor}
        >
          {isOpen
            ? buttonTextObject.toClose.text
            : buttonTextObject.toOpen.text}
        </ToggleReadButton>
      </ButtonDiv>
    </ReadMoreContainer>
  )
}

ToggleReadButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.oneOf(["yellow", "blue", "yellow-black", "gray"]),
  iconVariant: PropTypes.oneOf(["yellow"]),
}

ReadMoreText.propTypes = {
  children: PropTypes.node,
  seeMoreHeader: PropTypes.string,
  buttonTextObject: PropTypes.shape({
    toOpen: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
    toClose: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
  }),
  buttonColor: PropTypes.oneOf(["yellow", "blue", "yellow-black", "gray"]),
  hideAll: PropTypes.bool,
  buttonTop: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["fancy"]),
}

ReadMoreText.defaultProps = {
  buttonTextObject: {
    toOpen: { text: "Read more", icon: "plus" },
    toClose: { text: "Read less", icon: "minus" },
  },
  buttonColor: "gray",
}

export default ReadMoreText
