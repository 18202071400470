import React from "react"
import styled from "styled-components"
import { BOOKING_STEPS } from "@src/utils/constants/booking-process"

const BookingStep = styled.div`
  display: grid;
  grid-template-columns: 36px auto;
  grid-template-rows: repeat(4, auto);
  align-items: center;

  & .step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: dashed 1px ${props => props.theme.primary};
  }
  & div.step-details {
    margin-left: 16px;
    & p {
      font-family: "system-ui";
      margin: 0;
      &.number {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #979797; // Natasha Design
      }
      &.text {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.25px;
      }
    }
  }
  & span {
    text-align: center;
    height: 24px;
  }
`
const BookingProcess = () =>
  BOOKING_STEPS.map((step, index) => (
    <BookingStep key={step.text} className="booking-process-tile">
      <div className="step-icon">{step.icon}</div>
      <div className="step-details">
        <p className="number">{`Step ${index + 1}`}</p>
        <p className="text">{step.text}</p>
      </div>
      {index !== BOOKING_STEPS.length - 1 && <span>↓</span>}
    </BookingStep>
  ))
export default BookingProcess
