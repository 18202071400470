import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Currency from "@src/components/core-value-local-currency"
import Button from "@src/components/core-button"
import Image from "@src/components/core-image"
const CTA = styled.div`
  display: none;
  font-family: "system-ui";
  margin-top: 20px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #c9c9c9;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .cta-price-container {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.5px;
    & .cta-price {
      color: ${props => props.theme.green2};
      font-family: system-ui;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      margin-left: 4px;
      & .currency-code {
        margin-left: 4px;
        font-size: 24px;
      }
    }
  }
  & a {
    max-width: 610px;
    padding: 16px 28px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: ${props => props.theme.black};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
  & .core-icon-content {
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 21px;
    color: #979797; // Natasha Design
    margin-bottom: 10px;
    & > div {
      text-decoration: underline;
    }
    & > i {
      font-size: 16px;
    }
  }
  & .cta-text {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #daece4;
    padding: 30px 20px;
    max-width: 610px;
    margin-bottom: 0;
    & li {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0.25px;
      padding-left: 28px;
      & strong {
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          color: ${props => props.theme.primary};
        }
      }
      &:before {
        // Remove yellow dots
        border-radius: 0;
        background-color: transparent;
        top: 0;
        transform: none;
        // Black Box
        width: 22px;
        height: 22px;
        background: ${props => props.theme.black};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        // Required for custom bullet points with icon set
        font-family: "icons-10a-v2" !important;
        content: "\\eb52";
        color: ${props => props.theme.white}; // Natasha Design
      }
    }
  }
  & .cta-text-yellow {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: ${props => props.theme.backgroundPaleYellow};
    padding: 30px 20px;
    & p {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0.25px;
      margin: 0 0 0 10px;
      width: 90%;
    }
  }

  @media only screen and (${props => props.theme.screen.small.max}) {
    & .cta-price-container,
    & a {
      display: none;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    align-items: flex-start;
    margin: 0px;
    & a:hover {
      color: ${props => props.theme.primary};
    }
    & .core-icon-content {
      font-size: 14px;
      margin-bottom: 20px;
    }

    & .cta-text {
      height: 123px;
    }
  }
`
const BookingPriceCTA = ({
  price,
  map,
  bookingURL,
  askQuestion,
  terms,
  handleLDClick,
}) => (
  <CTA>
    <span className="cta-price-container">
      From{" "}
      {price && (
        <Currency
          className="cta-price"
          amount={price.amount}
          code={price?.currency?.code}
          showAbbr="after"
          applyRounding={!price.discount ? "auto" : false}
          discounted={!!price.discount}
        />
      )}
    </span>
    {askQuestion}
    {bookingURL && (
      <Button
        name="tour-start-booking-above-fold"
        size="full-width-large"
        href={bookingURL}
        rel="nofollow"
        external
        generateDataLayer={() => ({ "highlight-tile": "Prices-above-fold" })}
      >
        Book Now
      </Button>
    )}

    {terms && terms.lifetime ? (
      <ul className="cta-text primary">
        <li>
          <strong onClick={handleLDClick}>Lifetime Deposit</strong>
          {` - Lifetime deposit means if you need to cancel your booking you’ll get a credit for your full deposit to use on your next booking.`}
        </li>
      </ul>
    ) : (
      <div className="cta-text-yellow">
        <Image
          src="/icons/compass-cta.svg"
          alt="compass-image"
          width={40}
          height={50}
        />
        <p>
          Take the first step towards a private travel experience, customized
          just for you.
        </p>
      </div>
    )}
    {map}
  </CTA>
)

export default BookingPriceCTA

BookingPriceCTA.propTypes = {
  price: PropTypes.object,
  map: PropTypes.node,
  bookingURL: PropTypes.string,
  askQuestion: PropTypes.string,
  terms: PropTypes.shape({
    lifetime: PropTypes.bool,
    text: PropTypes.string,
  }),
  handleLDClick: PropTypes.func,
}
