const TOUR_REGION_CATEGORY_INCLUSIONS = [
  {
    id: 134,
    slug: "bike-tours",
  },
  {
    id: 64,
    slug: "hiking-tours",
  },
]

export const TOUR_REGION_CATEGORY_INCLUSION_IDS =
  TOUR_REGION_CATEGORY_INCLUSIONS.map(category => category.id)
export const TOUR_REGION_CATEGORY_INCLUSION_SLUGS =
  TOUR_REGION_CATEGORY_INCLUSIONS.map(category => category.slug)
