export const instantBookings = [
  // {
  //   uri: "https://www.10adventures.com/tour/ecuadorian-andes-amazon-adventure/",
  //   bookingUri:
  //     "https://explore.10adventures.com/10adventures/form/AndesandAmazonBookingForm/formperma/Hya6IEVZMfIxL92ST9_lB9kOE9HsPp47bSXXe7-oBUE",
  // },
  {
    uri: "https://www.10adventures.com/tour/trekking-the-avenue-of-the-volcanoes/",
    bookingUri:
      "https://explore.10adventures.com/10adventures/form/TrekkingEcuadorsVolcanoesTour/formperma/yifmYjeZ3cRNn0Fkem9M53QMx8CRS8giZFZMIOO9PlY",
  },
  {
    uri: "https://www.10adventures.com/tour/the-best-of-galapagos-adventure-tour/",
    bookingUri:
      "https://explore.10adventures.com/10adventures/form/BestoftheGalapagosAdventureTour/formperma/eZTiOp3J0ZXwhihXd9GwUzmj7VzLub_K7e6nL4TWCxw",
  },
  {
    uri: "https://www.10adventures.com/tour/machu-picchu-sacred-valley/",
    bookingUri:
      "https://explore.10adventures.com/10adventures/form/MachuPicchuandtheSacredValleyTour/formperma/VIuTDpGo4FXMlIrl7J4glCz4WKQDjAT4U3ohCk1RXdU",
  },

  {
    uri: "https://www.10adventures.com/tour/mount-kilimanjaro-on-machame-route-tour/",
    bookingUri:
      "https://explore.10adventures.com/10adventures/form/MountKilimanjaroontheMachameRoute1/formperma/AiVyPulFmxDGMqNj0eLa5IJsXLrd9mPIFs57A5sgVM8",
  },
  {
    uri: "https://www.10adventures.com/tour/mount-kilimanjaro-on-lemosho-route-tour/",
    bookingUri:
      "https://explore.10adventures.com/10adventures/form/MountKilimanjaroontheLemoshoRoute/formperma/E8ofLwkLNMa5F-_V5HYNfyZLh-cN3ziJ_QWc7In5258",
  },
]
