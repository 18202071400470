import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import { LocaleContext } from "@src/context/locale-context"
import Icon from "@src/components/core-icon"
import ModalContainer from "@src/components/container-modal"
import ContentCarousel from "./container-carousel"
const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ $count, $rows }) =>
    `repeat(${Math.ceil($count / $rows)}, 1fr)`};
  grid-template-rows: ${({ $rows }) => `repeat(${$rows}, 1fr)`};
  grid-gap: 10px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-gap: 40px;
  }
`
const Container = styled.div`
  z-index: 0;
  position: relative;
  background: #fbf8f5;
  height: 600px;
  margin-top: -40px;
  padding: 80px 16px 32px;
  display: flex;
  flex-direction: column;
  & h2 {
    font-family: system-ui;
    font-size: 28px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: #26383b;
  }
  & p {
    font-family: system-ui;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #667085;
    margin-bottom: 60px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    align-items: center;
    & p {
      margin-bottom: 1rem;
    }
    & > .text {
      max-width: 710px;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & .text {
      max-width: unset;
      width: 880px;
    }
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    & > .text {
      width: 1320px;
    }
  }
`
const Carousel = styled(ContentCarousel)`
  & > .carousel-content {
    height: 250px;
    & > * {
      margin: 0 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #a0a0a040;
    background: ${props => props.theme.white};
    color: ${props => props.theme.white};
    background: #1d1d1d;
    &:hover {
      color: ${props => props.theme.primary};
    }
    &:not(.show) {
      background: ${props => props.theme.white};
      color: #667085;
    }
    top: -30px;
    &.prev {
      left: 0px;
    }
    &.next {
      left: 42px;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    max-width: 690px;
    & > .carousel-content {
      height: 280px;
      > *:not(:last-child) {
        margin: 0 40px 0 0;
      }
    }
    & > button {
      top: -90px;
      &.prev {
        left: auto;
        right: 42px;
      }
      &.next {
        left: auto;
        right: 0px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    max-width: 880px;
    & > .carousel-content {
      height: 380px;
    }
    & > button {
      &.prev {
        left: auto;
        right: 66px;
      }
      &.next {
        left: auto;
        right: 24px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    max-width: 1320px;
    & > button {
      &.prev {
        left: auto;
        right: 66px;
      }
      &.next {
        left: auto;
        right: 24px;
      }
    }
  }
`
const Tile = styled.div`
  display: grid;
  height: 118px;
  grid-template-columns: 94px 160px;
  grid-template-rows: 32px 1fr;
  column-gap: 8px;
  row-gap: 8px;
  padding-right: 16px;
  border-radius: 8px;
  border: 1px solid #b8b8b8;
  font-family: system-ui;
  &.has-modal {
    :hover {
      cursor: pointer;
      border: 2px solid ${props => props.theme.primary};
    }
  }
  & > div {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 94px;
    height: 118px;
    & > img {
      height: calc(100% - 1px);
    }
  }
  & > h3 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.25px;
    color: #629d94;
    margin: 0;
    align-self: end;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > i {
      transform: rotate(45deg);
    }
  }
  & > p {
    height: 76px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    font-family: system-ui;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #1f1f23;
    margin: 0;
    // Reference: https://css-tricks.com/almanac/properties/l/line-clamp/
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-template-columns: 94px 200px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    grid-template-columns: 132px 240px;
    grid-template-rows: 48px 1fr;
    column-gap: 16px;
    height: 166px;
    & > div {
      width: 132px;
      height: 166px;
    }
    & > h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    }
    & > p {
      height: 84px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
`
const Wave = styled.div`
   {
    display: block;
    position: relative;
    margin-top: -5px;
    height: 80px;
    width: 100%;
    background: #fbf8f5;
    // Source: https://stackoverflow.com/questions/61157568/create-clip-path-wave-css-edges
    // Use: https://wave.novoselski.net/ Frequency 0.9, Amplitude 70, Phase 140, Points 50, Invert True
    clip-path: polygon(
      100% 0%,
      0% 0%,
      0% 48.79%,
      2% 47.21%,
      4% 45.88%,
      6% 44.82%,
      8% 44.03%,
      10% 43.54%,
      12% 43.34%,
      14% 43.44%,
      16% 43.83%,
      18% 44.52%,
      20% 45.49%,
      22% 46.73%,
      24% 48.22%,
      26% 49.95%,
      28% 51.89%,
      30% 54.03%,
      32% 56.32%,
      34% 58.75%,
      36% 61.28%,
      38% 63.87%,
      40% 66.5%,
      42% 69.14%,
      44% 71.74%,
      46% 74.28%,
      48% 76.72%,
      50% 79.03%,
      52% 81.19%,
      54% 83.15%,
      56% 84.91%,
      58% 86.44%,
      60% 87.71%,
      62% 88.71%,
      64% 89.43%,
      66% 89.87%,
      68% 90%,
      70% 89.84%,
      72% 89.38%,
      74% 88.63%,
      76% 87.6%,
      78% 86.3%,
      80% 84.75%,
      82% 82.97%,
      84% 80.98%,
      86% 78.81%,
      88% 76.48%,
      90% 74.03%,
      92% 71.49%,
      94% 68.88%,
      96% 66.24%,
      98% 63.61%,
      100% 61.02%
    );
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-bottom: 60px;
  }
`
const StyledModal = styled(ModalContainer)`
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 200px);
`
const tileData = [
  {
    country: "CA",
    image: {
      name: "Canadian",
      alt: "10Adventures-why-travel-WeAreCanadian",
    },
    header: "We're Canadian",
    text: "Book with a Canadian company whose mission is to help you travel the world.",
  },
  {
    country: "US",
    image: {
      name: "Americans",
      alt: "10Adventures-why-travel-HelpingAmericans",
    },
    header: "Helping Americans",
    text: "Book with a company whose mission is to help Americans travel the world.",
  },
  {
    country: "AU",
    image: {
      name: "Australians",
      alt: "10Adventures-why-travel-HelpingAustralians",
    },
    header: "Helping Australians",
    text: "Book with a company whose mission is to help Australians travel the world.",
  },
  {
    country: "UK",
    image: {
      name: "Brits",
      alt: "10Adventures-why-travel-HelpingBrits",
    },
    header: "Helping Brits",
    text: "Book with a company whose mission is to help Brits travel the world.",
  },
  {
    country: "GB",
    image: {
      name: "Brits",
      alt: "10Adventures-why-travel-HelpingBrits",
    },
    header: "Helping Brits",
    text: "Book with a company whose mission is to help Brits travel the world.",
  },
  {
    image: {
      name: "Accommodations",
      alt: "10Adventures-why-travel-Accommodations",
    },
    header: "Accommodations",
    text: "",
  },
  {
    image: {
      name: "Meals",
      alt: "10Adventures-why-travel-Meals",
    },
    header: "Meals",
    text: "",
  },
  {
    image: {
      name: "Transfers",
      alt: "10Adventures-why-travel-Transfers",
    },
    header: "Transfers",
    text: "Check out the 'What's Included' tab for more info on personal transfers during your tour.",
  },
  {
    image: {
      name: "Support",
      alt: "10Adventures-why-travel-LocalSupport",
    },
    header: "Local Support",
    text: "Travel stress-free knowing in-country local support is available by regular phone or WhatsApp to support you during your tour.",
  },
  {
    image: {
      name: "Credit card",
      alt: "10Adventures-why-travel-PaymentsMadeEasy",
    },
    header: "Payments Made Easy",
    text: "Enjoy the flexibility and ease of staggered payments in your own currency using your choice of credit card.",
  },
  {
    image: {
      name: "Route GPS",
      alt: "10Adventures-why-travel-RouteDescriptions",
    },
    header: "Route Resources",
    text: "Route notes, maps, and/or a trail app make navigation on this tour a breeze.",
  },
  {
    image: {
      name: "Park Entrance",
      alt: "10Adventures-why-travel-ParkEntranceFees",
    },
    header: "Park Entrance Fees",
    text: "Included where possible! Check the 'What's Included' tab for more info on park entrance fees during your tour.",
  },
  {
    image: {
      name: "Luggage",
      alt: "10Adventures-why-travel-LuggageTransfers",
    },
    header: "Luggage transfers",
    text: "Take a load off, with optional or included luggage transfers. Check inclusions for further details.",
  },
  /* These will be used later
  {
    image: {
      name: "Free roaming",
      alt: "10Adventures-why-travel-FreeRoaming",
    },
    header: "Free Roaming",
    text: "Description about getting a free 1GB E-Sim here...",
  },
  {
    image: {
      name: "Easy cancel",
      alt: "10Adventures-why-travel-EasyCancel",
    },
    header: "Easy Cancel",
    text: "A tid bit about easy or free cancellation on trips",
  },
  {
    image: {
      name: "Holiday video",
      alt: "10Adventures-why-travel-HolidayVideo",
    },
    header: "Holiday Video",
    text: "Submit your personal photos and videos to get a free trip video......",
  },
  */
]
const WhyTravellerTile = ({ image, header, text, onClick, modal }) => {
  return (
    <Tile onClick={onClick} className={modal ? "has-modal" : ""}>
      <Image height={166} width={132} placeholder {...image} />
      <h3>
        {header}
        {modal ? <Icon iconFontSize="16px" glyph="v2-cross-circle" /> : null}
      </h3>
      <p>{text}</p>
    </Tile>
  )
}

const WhyTravellers = ({ accommodations, inclusions }) => {
  const [modalContent, setModalContent] = useState(null)
  // Check inclusions text for Meals and Accommodations text
  const inclusionsSplit =
    inclusions && inclusions.length && inclusions.split("<h3>")
  let accommodationsText
  let mealsText
  if (inclusionsSplit && inclusionsSplit.length) {
    accommodationsText =
      inclusionsSplit &&
      inclusionsSplit.length &&
      inclusionsSplit.filter(string => string.startsWith("Accommodations"))
    mealsText =
      inclusionsSplit &&
      inclusionsSplit.length &&
      inclusionsSplit.filter(string => string.startsWith("Meals"))
  }
  if (accommodationsText && accommodationsText.length) {
    tileData[5].text = accommodationsText[0].match(/<p>([\s\S]*?)<\/p>/)[1]
  }
  if (mealsText && mealsText.length) {
    tileData[6].text =
      "Don't worry about meals. " + mealsText[0].match(/<p>([\s\S]*?)<\/p>/)[1]
  }
  // Accommodation Modal Text
  if (accommodations) {
    tileData[5].modal = accommodations
  }
  // Localized Country Info
  const { country } = useContext(LocaleContext)
  const filteredData = tileData.filter(
    data =>
      (["UK", "GB", "CA", "AU", "US"].includes(country)
        ? data.country === country
        : data.country === "CA") ||
      (!data.country && !!data.text.length)
  )
  // Handlers
  const handleModalOpen = modalData => {
    setModalContent(modalData)
  }

  // Render Items
  const Items =
    filteredData &&
    filteredData.length &&
    filteredData.map(data => {
      const { alt, name } = data.image
      const cloudfrontLink =
        "https://d3owbckoeihl9o.cloudfront.net/images/WhyTravellers/"

      return (
        <WhyTravellerTile
          key={data.header}
          image={{
            alt,
            src: `${cloudfrontLink}${name}.png`,
            srcSet: `${cloudfrontLink}${name}.png 1x, ${cloudfrontLink}${name}@2x.png 2x, ${cloudfrontLink}${name}@3x.png 3x`,
          }}
          header={data.header}
          text={data.text}
          modal={data.modal}
          onClick={data.modal ? () => handleModalOpen(data.modal) : null}
        />
      )
    })

  const renderGrids = content => {
    const grids = []

    for (let i = 0; i < content.length; i += 2) {
      // Create a 2x1 grid with two items
      grids.push(
        <Grid $count={2} $rows={2}>
          {content[i]}
          {content[i + 1] !== undefined && content[i + 1]}
        </Grid>
      )
    }

    return grids
  }
  return (
    <>
      <Container>
        <div className="text">
          <h2>Why travellers choose this tour</h2>
          <p>{`At 10Adventures, we take care of the details so that our guests can focus on making memories. Enjoy these key features on this tour.`}</p>
        </div>
        {Items && Items.length && (
          <Carousel
            icons={{
              left: "v2-arrow-small-left",
              right: "v2-arrow-small-right",
            }}
            mobileSizeOnly
            alwaysShowButtons
          >
            {Items.length > 1 ? renderGrids(Items) : { Items }}
          </Carousel>
        )}
      </Container>
      <Wave className="wave" />
      {
        <StyledModal
          isOpen={!!modalContent}
          onClose={() => setModalContent(false)}
        >
          {accommodations}
        </StyledModal>
      }
    </>
  )
}

export default WhyTravellers

WhyTravellerTile.propTypes = {
  image: PropTypes.shape({ ...ImagePropTypes }),
  header: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  modal: PropTypes.node,
}
WhyTravellers.propTypes = {
  accommodations: PropTypes.node,
  inclusions: PropTypes.string,
}
