import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const Bar = styled.div`
  display: flex;
  height: 8px;
  border-radius: 20px;
  ${props =>
    props.completed
      ? css`
          width: ${props => props.completed}%;
          background-color: ${props => props.theme.primary};
        `
      : ""}
`
const ProgressBar = ({ completed, className }) => {
  return (
    <Bar
      className={`progress-bar ${className ? className : ""}`}
      completed={completed}
    ></Bar>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

export default ProgressBar
