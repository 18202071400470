import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RatingStars from "@src/components/core-rating-stars"

const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "system-ui";
  letter-spacing: 0.5px;
  & .name {
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    color: ${props => props.theme.darkerGray};
    word-break: break-all;
    margin-top: 10px;
  }
  & .date {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #737373; // Natasha Design
    margin-bottom: 10px;
  }

  & .review-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
  }
`
const TourOperatorReview = ({ review, className }) => {
  return (
    <StyledReview className={className}>
      <RatingStars value={review.rating} />
      <span className="name">{review.name}</span>
      <span className="date">{review.date}</span>
      <p className="review-content">{review.content}</p>
    </StyledReview>
  )
}
TourOperatorReview.propTypes = {
  review: PropTypes.shape({
    name: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  className: PropTypes.string,
}
export default TourOperatorReview
