import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Currency from "@src/components/core-value-local-currency"
import Button from "@src/components/core-button"
import { exists, window, document } from "browser-monads"

const StickyCTA = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px ${props => (props.$chatShowing ? "80px" : "16px")} 20px 16px;
  z-index: 20;
  background: ${props => props.theme.bg1};
  box-shadow: 4px 0px 4px 0px rgba(124, 124, 124, 0.25);
  font-family: "system-ui";
  & .sticky-cta-price-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    & .sticky-cta-price {
      color: ${props => props.theme.green2};
      font-family: system-ui;
      font-size: 26px;
      font-weight: 700;
      line-height: auto;
      letter-spacing: 0.5px;
      & .currency-code {
        margin-left: 4px;
        font-size: 16px;
      }
    }
  }
  & a {
    max-width: 150px;
    padding: 8px 14px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.005em;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    display: none;
  }
`
const StickyBookingPriceCTA = ({ price, bookingURL }) => {
  let chatShowing
  if (exists(window) && exists(document)) {
    chatShowing = document.getElementsByClassName("zsiq_floatmain").length
  }

  return (
    <StickyCTA $chatShowing={chatShowing}>
      <span className="sticky-cta-price-container">
        From{" "}
        {price && (
          <Currency
            className="sticky-cta-price"
            amount={price.amount}
            code={price?.currency?.code}
            showAbbr="after"
            applyRounding={!price.discount ? "auto" : false}
            discounted={!!price.discount}
          />
        )}
      </span>
      {bookingURL && (
        <Button
          name="tour-start-booking-sticky"
          href={bookingURL}
          color="yellow-black"
          rel="nofollow"
          external
          generateDataLayer={() => ({ "highlight-tile": "Prices-sticky-CTA" })}
        >
          Book Now
        </Button>
      )}
    </StickyCTA>
  )
}

export default StickyBookingPriceCTA

StickyBookingPriceCTA.propTypes = {
  price: PropTypes.object,
  bookingURL: PropTypes.string,
}
