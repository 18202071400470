import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import InputField from "@src/components/input-field"
import { formatNumber as formatNumberUtil } from "@src/utils/numbers"
const InputNumber = ({
  onChange,
  onBlur,
  value: defaultValue,
  formatNumber,
  decimals,
  separator,
  padding,
  min,
  max,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || "")
  const formatValue = useCallback(
    value => {
      // parse the value
      value = value || ""
      value = value && value.replace(/[^\d.-]/g, "")
      value = value && parseFloat(value)

      if ((min || min === 0) && value < min) {
        value = min
      }
      if ((max || max === 0) && value > max) {
        value = max
      }

      // format the value
      if ((value || value === 0) && !isNaN(value)) {
        if (formatNumber && typeof formatNumber === "function") {
          return formatNumber(value, decimals, separator, padding)
        }
        return formatNumberUtil(value, decimals, separator, padding)
      }

      return value
    },
    [decimals, separator, padding, min, max, formatNumber]
  )
  const handleChange = event => {
    // update the value, but don't format it in real-time to avoid interfering with user's input experience
    setValue(event.target.value)

    // bubble up the formatted value since that's the one that matters outside the component
    if (onChange) {
      onChange(formatValue(event.target.value))
    }
  }
  const handleBlur = event => {
    // format the value
    const value = formatValue(event.target.value)

    // set field value to the formatted value (ok to adjust it now that user is finished inputting)
    setValue(value)

    // bubble up the formatted value
    if (onBlur) {
      onBlur(value)
    }
  }
  useEffect(() => {
    setValue(defaultValue || "")
  }, [defaultValue])
  return (
    <InputField
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      {...props}
    />
  )
}
InputNumber.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  formatNumber: PropTypes.func,
  decimals: PropTypes.number,
  separator: PropTypes.string,
  padding: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
}
InputNumber.defaultProps = {
  decimals: 0,
  separator: ",",
  padding: 0,
}
export default InputNumber
