import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"

const HighlightList = styled.div`
  display: grid;
  gap: 10px;
  margin-bottom: 10px;
  font-family: "system-ui";
  position: relative;
`

const Highlight = styled(IconDetailContentTile)`
  align-items: center;
  & > .icon {
    margin-right: 12px;
    font-size: 23px;
  }
  & > .content {
    & > p {
      margin: 0;
    }
  }
  margin-bottom: 12px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    align-items: flex-start;
  }
`

const TourHighlightList = ({ highlights }) => {
  return (
    <HighlightList>
      {highlights.map((highlight, index) => (
        <Highlight
          key={index}
          glyph={"v2-solid-circle-small"}
          details={highlight.details}
        />
      ))}
    </HighlightList>
  )
}
TourHighlightList.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape({ details: PropTypes.string })),
}
export default TourHighlightList
