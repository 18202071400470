import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Currency from "@src/components/core-value-local-currency"
import Button from "@src/components/core-button"

const StyledTable = styled.table`
  border-collapse: collapse;
  & thead {
    & tr {
      height: 50px;
      & th {
        &:not(:first-child) {
          padding-left: 30px;
        }
      }
    }
  }
  & tbody {
    & tr {
      border-top: 1px solid black;
      height: 50px;
      & td {
        &:first-child {
          font-weight: 500;
          text-align: left;
        }
        &:not(:first-child) {
          text-align: center;
          height: inherit;
          padding-left: 30px;
        }
      }
    }
  }
  & caption {
    font-size: 12px;
    caption-side: bottom;
    text-align: left;
  }
`
const PricesDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledCurrency = styled(Currency)`
  font-weight: bold;
  ${props =>
    props.discount
      ? css`
          text-decoration: line-through;
        `
      : null}
`
const DiscountedCurrency = styled(StyledCurrency)`
  color: ${props => props.theme.red};
  text-decoration: none;
`

const DayOfWeekPricesTable = ({ dayofWeekData, className, bookingURL }) => {
  const days =
    dayofWeekData.prices &&
    dayofWeekData.prices.map((day, index) => {
      return (
        <tr key={index}>
          <td scope="row">{day.day}</td>
          {day.price ? (
            <>
              <td>
                <PricesDiv>
                  {day.price?.discount &&
                  day.price?.amount &&
                  day.price?.amountBase ? (
                    <DiscountedCurrency
                      amount={day.price.amount}
                      code={day.price?.currency.code}
                      showAbbr="after"
                    />
                  ) : undefined}
                  <StyledCurrency
                    amount={day.price.amountBase}
                    code={day.price?.currency.code}
                    discount={day.price?.discount}
                    showAbbr="after"
                  />
                </PricesDiv>
              </td>
              <td>
                <Button
                  name={`tour-book-now-dow-${day.day
                    .substr(0, 3)
                    .toLowerCase()}`}
                  color="yellow-black"
                  variant="rectangle"
                  mobileSizeOnly
                  href={bookingURL}
                  rel="nofollow"
                  external
                  generateDataLayer={() => ({
                    "tour-dow": day.day,
                    "highlight-tile": "Dates & Prices",
                  })}
                >
                  Book Now
                </Button>
              </td>
            </>
          ) : (
            <>
              <td>Not Available</td>
              <td />
            </>
          )}
        </tr>
      )
    })

  return (
    <>
      <StyledTable className={className}>
        <thead>
          <tr>
            <th>Day Of Week</th>
            <th>Price Per Person {dayofWeekData.footer && "*"}</th>
            <th />
          </tr>
        </thead>
        <tbody>{days}</tbody>
        {dayofWeekData.footer && <caption>{dayofWeekData.footer}</caption>}
      </StyledTable>
    </>
  )
}

DayOfWeekPricesTable.propTypes = {
  dayofWeekData: PropTypes.any,
  className: PropTypes.string,
  bookingURL: PropTypes.string,
}

export default DayOfWeekPricesTable
