import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Button from "@src/components/core-button"
import Icon from "./core-icon"
import Link from "./core-link"
// Lifetime Deposit
const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0;
  background: ${props => props.theme.bg1};
  z-index: 900;
  padding: 24px;
  overflow-y: scroll;

  &.hidden {
    display: none;
  }
  & button,
  & p,
  & li {
    font-size: 16px;
    line-height: 24px;
  }
  & button {
    background: none;
    color: #667085;
    padding: 0;
    & .icon {
      font-size: 20px;
      margin-right: 4px;
      vertical-align: text-top;
    }
    &:hover {
      color: ${props => props.theme.black};
    }
  }

  & .red-header {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: ${props => props.theme.red2};
    margin: 16px 0;
  }

  & ul > li::before {
    background-color: ${props => props.theme.red2};
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 40%;
    min-width: 320px;
    right: 0;
    box-shadow: -6px 0px 6px 0px rgba(61, 61, 61, 0.16);
  }
`

const LifetimeDeposit = ({ className, handleClose }) => (
  <Container className={`lifetime-deposit ${className ? className : ""}`}>
    <Button onClick={handleClose}>
      <Icon glyph="v2-arrow-small-left" />
      Back
    </Button>
    <p className="red-header">Lifetime Deposit</p>

    <strong>Lifetime Deposits</strong>
    <p>{`Is there anything better than booking a trip, and being able to have peace of mind when doing so? At 10Adventures, you're covered with Lifetime Deposits! As world travellers ourselves, we understand how important this is and that's why we've introduced our Lifetime Deposits program for most tours on 10Adventures.`}</p>

    <strong>What Does Lifetime Deposit Mean?</strong>
    <p>{`It's simple! You pay your deposit to confirm your trip, and if you have to cancel, we'll keep your full deposit amount on file for you to use towards any future booking with 10Adventures. You can use this deposit within the same year, or even 10 years later...it's your call!`}</p>

    <strong>Everything You Need to Know About Lifetime Deposits</strong>
    <ul className="primary">
      <li>
        A voucher in the amount of your deposit paid will be issued to you by
        email on cancellation of your tour booking.
      </li>
      <li>
        {`Lifetime Deposits are transferable, so if you'd like to give your deposit to someone else to book a tour on 10Adventures, just let us know.`}
      </li>
      <li>
        You can only use one lifetime deposit towards a future booking (not
        multiple).
      </li>
      <li>{`If your Lifetime Deposit amount is more than the total tour value you're re-booking, no cash value or credit will be issued.`}</li>
      <li>Not all tours on 10Adventures are eligible for Lifetime Deposits.</li>
      <li>
        {`Lifetime Deposits are transferable, so if you'd like to give your deposit to someone else to book a tour on 10Adventures, just let us know.`}
      </li>
      <li>Lifetime Deposits cannot be redeemed for cash value.</li>
      <li>
        Bookings with missed payments are not eligible for Lifetime Deposits.
      </li>
    </ul>
    <p>
      Check out complete details in the{" "}
      <Link to="/booking-terms-and-conditions-2024/#cancel" external>
        Booking Terms and Conditions.
      </Link>
    </p>
  </Container>
)

export default LifetimeDeposit

LifetimeDeposit.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
}
