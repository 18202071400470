import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Tooltip from "@src/components/core-tooltip"
import Icon from "@src/components/core-icon"
import { formatNumber } from "@src/utils/numbers"
const StyledTooltip = styled(Tooltip)`
  & .tooltip-trigger {
    color: ${props => props.theme.primary};
    & .icon {
      font-size: 20px;
    }
  }
  & .tooltip-container {
    width: auto;
    font-weight: bold;
  }
`
const InputWrapper = styled.div`
  display: flex;
  /* prettier-ignore */
  &:not(:hover):not(.has-value) > ${StyledTooltip},
  &:not(:hover) > ${StyledTooltip}.active ~ ${StyledTooltip},
  &:hover > ${StyledTooltip}:hover ~ ${StyledTooltip} {
    & .tooltip-trigger {
      color: ${props => props.theme.lightGray};
    }
  }
`
const InputRatingStars = ({
  className,
  id,
  name,
  value: defaultValue,
  onChange,
  min,
  max,
}) => {
  const [value, setValue] = useState(null)
  const updateValue = useCallback(
    newValue => {
      if (newValue < min) {
        newValue = min
      } else if (newValue > max) {
        newValue = max
      }
      setValue(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [setValue, min, max, onChange]
  )
  useEffect(() => {
    if (updateValue) {
      updateValue(defaultValue)
    }
  }, [defaultValue, updateValue])
  return (
    <InputWrapper
      className={`${className} ${
        (!min && value > 0) || (min && value >= min) ? " has-value" : ""
      }`}
      id={id}
    >
      {(value || value === 0) && (
        <input type="hidden" name={name} value={value} />
      )}
      {[...Array(max)].map((e, i) => (
        <StyledTooltip
          key={`${id}-rating-star-${i + 1}`}
          className={i + 1 === value ? "active" : undefined}
          triggerElement={<Icon glyph="star" />}
          triggerProps={{
            onClick: () => {
              updateValue(i + 1)
            },
          }}
        >
          {formatNumber(i + 1, 1)} / {formatNumber(max, 1)}
        </StyledTooltip>
      ))}
    </InputWrapper>
  )
}
InputRatingStars.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
}
InputRatingStars.defaultProps = {
  min: 0,
  max: 5,
}
export default InputRatingStars
