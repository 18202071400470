import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Icon from "@src/components/core-icon"
import ProgressBar from "@src/components/core-progress-bar"
import RatingStars from "@src/components/core-rating-stars"

const textToNumber = { one: "1", two: "2", three: "3", four: "4", five: "5" }

const StyledReviewOverview = styled.div`
  font-family: "system-ui";
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 560px;
`

const OverallRating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    &.overall {
      font-family: "system-ui";
      font-size: 40px;
      font-weight: 900;
      line-height: 56px;
      letter-spacing: 0.45px;
    }
    &.summary {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #4f4f4f; // Natasha Design
      margin-top: 6px;
    }
  }
`
const RatingInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 14px 0;

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: 0px;
  }
`

const StarRatingCounts = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
  & > .star-rating-count {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: auto;
    align-items: center;
    padding: 5px 0;
    & > span {
      font-size: 16px;
      line-height: 20px;
      width: 46px;
      & > .icon {
        vertical-align: text-bottom;
        margin-left: 5px;
      }
    }
  }
`

const ReviewOverview = ({ overall, numReviews, className }) => {
  const { total, ...reviewCounts } = numReviews
  return (
    <StyledReviewOverview className={className}>
      <StarRatingCounts>
        {Object.keys(reviewCounts)
          .reverse()
          .map(reviewCount => {
            const count = reviewCounts[reviewCount] || 0
            return (
              <div className="star-rating-count" key={reviewCount}>
                <span>
                  {`${textToNumber[reviewCount]}`}{" "}
                  <Icon glyph="star" primary iconFontSize="20px" />
                </span>
                <ProgressBar completed={Math.round((count / total) * 100)} />
              </div>
            )
          })}
      </StarRatingCounts>
      <RatingInformation>
        <OverallRating>
          <span className="overall">{overall ? overall.toFixed(1) : ""}</span>
          <RatingStars value={overall} size="condensed" />
          <span className="summary">{`${total} ${
            total == 1 ? "rating" : "ratings"
          }`}</span>
        </OverallRating>
      </RatingInformation>
    </StyledReviewOverview>
  )
}
ReviewOverview.propTypes = {
  overall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numReviews: PropTypes.shape({
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    five: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    four: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    three: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    two: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    one: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  className: PropTypes.string,
}
export default ReviewOverview
