import React, { useContext, useState, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import TourOperatorReviewsService from "@src/services/reviews/tour-operator-reviews"
import { UserContext } from "@src/context/user-context"
import Form, { FormActions } from "@src/components/form"
import FormFieldInputTextarea from "@src/components/form-field-input-textarea"
import FormFieldInputRatingStars from "@src/components/form-field-input-rating-stars"
import Link from "@src/components/core-link"
import Button from "@src/components/core-button"
import { formatDate } from "@src/utils/dates"
import ModalContainer from "@src/components/container-modal"

const AddReviewContainer = styled.div`
  width: 100%;
  padding: 14px 0;

  & .review-details {
    margin-bottom: 10px;
    font-weight: 500;
    & > label {
      display: inline-block;
      font-weight: normal;
      font-style: italic;
      font-size: 0.8em;
    }
  }

  & textarea {
    max-width: 100%;
  }
`
const GoogleModal = styled(ModalContainer)`
  & a {
    width: 100%;
  }
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    max-width: 400px;
  }
`

const AddOperatorReview = ({ operatorId, onReviewAdded }) => {
  const { user } = useContext(UserContext)
  const [formStatus, setFormStatus] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const handleFormSubmit = useCallback(
    async (values, resetForm) => {
      setFormStatus({
        processing: "Thanks! Just one moment while we post your review...",
      })
      try {
        await TourOperatorReviewsService.createOperatorReview(
          operatorId,
          values
        )
        // API call didn't throw an error, assume a success
        setFormStatus({
          success: "Thanks for the review!",
        })
        if (values.ratings.overall === 5) {
          setShowModal(true)
        }
        if (onReviewAdded) {
          onReviewAdded()
        }
        resetForm()
      } catch (error) {
        setFormStatus({
          error: "There was an error posting your review.",
        })
      }
    },
    [operatorId, onReviewAdded, setFormStatus]
  )
  const onModalClose = () => {
    setShowModal(false)
  }
  return (
    <>
      <AddReviewContainer>
        <h3>Add a comment</h3>
        {user ? (
          <Form name="operator-review" onSubmit={handleFormSubmit}>
            <div className="review-details">
              <label>Posting as:</label>{" "}
              <span className="review-author">
                {user?.profile?.displayName}
              </span>
              {", "}
              <span className="review-date">
                {formatDate(new Date(), "MMMM yyyy")}
              </span>
            </div>
            <FormFieldInputTextarea
              name="review-content"
              placeholder="Share your experience with fellow adventurers..."
              rows="6"
            />
            <FormFieldInputRatingStars
              label="Rating"
              name="ratings[overall]"
              value={5}
              min={1}
              max={5}
            />
            <FormActions
              className="horizontal"
              submitting={
                formStatus && formStatus.processing ? true : undefined
              }
            >
              {(formStatus?.success && (
                <p className="form-success">{formStatus.success}</p>
              )) ||
                (formStatus?.error && (
                  <p className="form-error">{formStatus.error}</p>
                )) ||
                (formStatus?.processing && (
                  <p className="form-info">{formStatus.processing}</p>
                ))}
              <Button
                type="submit"
                disabled={
                  formStatus && formStatus.processing ? true : undefined
                }
                color="yellow-black"
              >
                Post
              </Button>
            </FormActions>
          </Form>
        ) : (
          <p>
            You must be <Link to="/account/login/">logged in</Link> to post a
            comment.
          </p>
        )}
      </AddReviewContainer>
      <GoogleModal
        id="google-review-modal"
        isOpen={showModal}
        onClose={onModalClose}
        closeIcon="v2-close"
      >
        <h2 className="fancy">Thank you!</h2>
        <p>Thank you for submitting your review!</p>
        <p>{`If you enjoyed your tour please consider leaving a Google review for 10Adventures to help others discover the world of custom adventure travel.`}</p>
        <Button
          to={process.env.GATSBY_10A_GOOGLE_SUBMIT_REVIEW}
          onClick={onModalClose}
        >
          Google Review
        </Button>
      </GoogleModal>
    </>
  )
}
AddOperatorReview.propTypes = {
  operatorId: PropTypes.number,
  onReviewAdded: PropTypes.func,
}
export default AddOperatorReview
