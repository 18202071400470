export const REVIEW_SORT_OPTIONS = [
  {
    text: "Newest",
    value: "Date_DESC",
  },
  {
    text: "Oldest",
    value: "Date_ASC",
  },
  {
    text: "Highest",
    value: "Rating_DESC",
  },
  {
    text: "Lowest",
    value: "Rating_ASC",
  },
]
