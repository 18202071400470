import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import IconDetailContentTile from "@src/components//core-icon-detail-tile"
const About = styled.div`
  p {
    font-family: "system-ui";
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    color: ${props => props.theme.green2};
    margin: 20px 0 0;
  }
  & .core-icon-content {
    font-family: "system-ui";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin: 10px 0;
    align-items: center;
    & .icon {
      font-size: 20px;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: 20px 0 6px;
    & .about-details {
      padding: 10px 0;
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-template-rows: repeat(3, auto);
      column-gap: 30px;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      & .green-text {
        grid-row: 1 / 2;
        grid-column: 1 / 4;
      }
    }

    & p {
      margin: 0;
    }
    @media only screen and (${props => props.theme.screen.medium.min}) {
      & .about-details {
        display: flex;
        column-gap: 0px;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`
const AboutTour = ({ tourIcons }) => (
  <About className="about-tour-container">
    <div className="about-details">
      <p className="green-text">About this tour:</p>
      {tourIcons &&
        tourIcons.length &&
        tourIcons.map(item => {
          let { label, icon } = item
          switch (icon) {
            case "time2":
              icon = "v2-moon"
              label = label.split("/")[0].trim()
              break
            case "distance":
              icon = "v2-backpack"
              break

            case "camping":
            case "climb":
              icon = "v2-bed"
              break
          }
          return (
            <IconDetailContentTile key={label} glyph={icon} color="gray">
              {label}
            </IconDetailContentTile>
          )
        })}
    </div>
  </About>
)

export default AboutTour

AboutTour.propTypes = {
  tourIcons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}
